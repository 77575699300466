// frontend/src/components/auth/ProtectedSuperUserRoute.jsx
import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../auth/authcontext';

const ProtectedSuperUserRoute = ({ children }) => {
  const { isAuthenticated, isLoading, isSuperuser } = useContext(AuthContext);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated || !isSuperuser) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default ProtectedSuperUserRoute;