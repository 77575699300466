import React from 'react';

const faqs = [
  {
    title: "What is the Survey Strategy for Success?",
    content: (
      <>
        <p>To maximize your success and earnings on our platform, we recommend following these key strategies:</p>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Be Honest:</strong> Always provide truthful responses. Cheating can lead to a ban and reduce your survey opportunities.</li>
          <li><strong>Do Not Use a VPN:</strong> Avoid using VPNs, as they interfere with data quality and survey matching.</li>
          <li><strong>Complete Your Profile:</strong> Answering profile questions honestly helps us find suitable surveys for you.</li>
          <li><strong>Get Matched to Surveys:</strong> A complete profile allows for better matching, increasing your chances of qualifying.</li>
        </ul>
      </>
    ),
  },
  {
    title: "What Are the Participation Rules?",
    content: (
      <>
        <p>Adhere to the following rules to ensure a fair experience for all users:</p>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Daily Survey Completion Requirement:</strong> Complete at least one survey per day to qualify for the end-of-month bonus.</li>
          <li><strong>User Conduct:</strong> Avoid behaviors that could result in a ban, such as false information, VPN use, or automated tools.</li>
          <li><strong>Agreement to Terms:</strong> By using our platform, you agree to our <a href="/terms" className="text-blue-500 underline">Terms of Service</a>.</li>
        </ul>
      </>
    ),
  },
  {
    title: "How Does Leveling Up Work?",
    content: (
      <>
        <p>Our platform features a leveling system that rewards you as you complete more surveys:</p>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Starting Level:</strong> All users begin at <strong>Level 1</strong>.</li>
          <li><strong>Advancing Levels:</strong> You level up by completing a certain number of surveys:
            <ul className="list-disc list-inside ml-6">
              <li><strong>Level 2:</strong> After 20 survey completions.</li>
              <li><strong>Level 3:</strong> After 40 survey completions.</li>
              <li><strong>Level 4:</strong> After 60 survey completions.</li>
              <li><strong>Level 5:</strong> After 80 survey completions.</li>
              <li><strong>Level 6:</strong> After 100 survey completions.</li>
              <li><strong>Level 7:</strong> After 120 survey completions.</li>
            </ul>
          </li>
          <li><strong>Benefits of Leveling Up:</strong> Higher levels unlock increased per-survey incentives.</li>
        </ul>
      </>
    ),
  },
  {
    title: "What Are the Per-Survey Incentives at Each Level?",
    content: (
      <>
        <p>As you progress through levels, your per-survey incentive increases:</p>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Level 1:</strong> Earn <strong>30%</strong> of the partner incentive.</li>
          <li><strong>Level 2:</strong> Earn <strong>35%</strong> of the partner incentive.</li>
          <li><strong>Level 3:</strong> Earn <strong>40%</strong> of the partner incentive.</li>
          <li><strong>Level 4:</strong> Earn <strong>45%</strong> of the partner incentive.</li>
          <li><strong>Level 5:</strong> Earn <strong>50%</strong> of the partner incentive.</li>
          <li><strong>Level 6:</strong> Earn <strong>55%</strong> of the partner incentive.</li>
          <li><strong>Level 7:</strong> Earn <strong>60%</strong> of the partner incentive.</li>
        </ul>
      </>
    ),
  },
  {
    title: "How Are Monthly Bonus Rewards Awarded?",
    content: (
      <>
        <p>Each month, the top three users who complete the most surveys receive additional bonus rewards:</p>
        <ul className="list-disc list-inside ml-4">
          <li><strong>1st Place:</strong> $500</li>
          <li><strong>2nd Place:</strong> $300</li>
          <li><strong>3rd Place:</strong> $200</li>
        </ul>
        <p><strong>Note:</strong> To qualify, you must meet the daily survey completion requirement and adhere to all participation rules.</p>
      </>
    ),
  },
  {
    title: "Why Is There a 20-Day Delay in Distributing End-of-Month Bonuses?",
    content: (
      <>
        <p>The 20-day delay ensures the integrity and quality of survey responses. This period allows us to:</p>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Review All Survey Completions:</strong> Verify the accuracy and honesty of responses.</li>
          <li><strong>Identify and Correct Reversals or Reconciliations:</strong> Adjust for any surveys deemed fraudulent or unacceptable by our research partners.</li>
        </ul>
        <p><strong>Example:</strong> If you earn a bonus in January, you will receive your reward on <strong>February 20th</strong>.</p>
      </>
    ),
  },
  {
    title: "When Can I Redeem My Survey Rewards?",
    content: (
      <>
        <p>Incentives earned for completing surveys are available 20 days after the day you complete. This delay allows us to:</p>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Review All Survey Completions:</strong> Verify the accuracy and honesty of responses.</li>
          <li><strong>Identify and Correct Reversals or Reconciliations:</strong> Adjust for any surveys deemed fraudulent or unacceptable by our research partners.</li>
        </ul>
      </>
    ),
  },
  {
    title: "How Can I Ensure My Survey Completions Count Toward Monthly Totals?",
    content: (
      <>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Provide Honest Responses:</strong> Always answer survey questions truthfully.</li>
          <li><strong>Avoid Disqualifications:</strong> Being screened out or disqualified does not count as a completion.</li>
          <li><strong>Monitor Your Account:</strong> Regularly check for any adjustments to your survey totals.</li>
        </ul>
      </>
    ),
  },
  {
    title: "What Behaviors Can Result in Being Banned?",
    content: (
      <>
        <p>Activities that compromise the integrity of the platform can lead to a ban, including:</p>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Falsifying Personal Information:</strong> Claiming to be from a different country.</li>
          <li><strong>Using VPNs:</strong> Masking your true location.</li>
          <li><strong>Automated Survey Completion:</strong> Using bots or scripts.</li>
          <li><strong>Rushing Through Surveys:</strong> Providing low-quality data by not engaging properly.</li>
        </ul>
        <p><strong>Consequences of a Ban:</strong></p>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Forfeiture of Points and Incentives:</strong> All accumulated rewards will be lost.</li>
          <li><strong>Ineligibility for Bonuses:</strong> You will not receive any end-of-month bonuses.</li>
        </ul>
      </>
    ),
  },
  {
    title: "Can I Use a VPN While Taking Surveys?",
    content: (
      <>
        <p><strong>No.</strong> Using a VPN is prohibited because it can interfere with data quality and survey matching. Researchers need accurate location data to provide relevant surveys.</p>
      </>
    ),
  },
  {
    title: "Why Is Honesty Important in Surveys?",
    content: (
      <>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Quality Data:</strong> Honest responses ensure the data collected is reliable and valuable to researchers.</li>
          <li><strong>More Survey Opportunities:</strong> Providing truthful information helps us match you with surveys you are likely to qualify for.</li>
          <li><strong>Avoiding Penalties:</strong> Dishonest responses can lead to disqualifications or bans.</li>
        </ul>
      </>
    ),
  },
  {
    title: "How Do Profile Questions Help Me?",
    content: (
      <>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Better Matching:</strong> Profile questions gather information about you to match you with appropriate surveys.</li>
          <li><strong>Increased Success Rate:</strong> Honest answers increase your chances of qualifying for and completing surveys.</li>
          <li><strong>Personalized Experience:</strong> Helps us understand your interests and preferences for future surveys.</li>
        </ul>
      </>
    ),
  },
  {
    title: "What Should I Do If I Have Questions or Concerns?",
    content: (
      <>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Contact Support:</strong> For issues regarding survey completions or bonuses, please reach out to support at <strong>support@levelrev.com</strong>.</li>
          <li><strong>Stay Informed:</strong> Regularly check your account and emails for updates or important information.</li>
        </ul>
      </>
    ),
  },
  {
    title: "Key Points to Remember",
    content: (
      <>
        <ul className="list-disc list-inside ml-4">
          <li><strong>Accuracy Matters:</strong> Always provide honest and thoughtful responses.</li>
          <li><strong>Consistency is Key:</strong> Participate daily or maintain an average to meet requirements.</li>
          <li><strong>Monitor Your Progress:</strong> Keep track of your survey completions and levels.</li>
          <li><strong>We're Here to Help:</strong> Don't hesitate to contact us with any questions.</li>
        </ul>
        <p>We appreciate your commitment to maintaining the quality and integrity of our platform. Thank you for being a valued member of our community!</p>
      </>
    ),
  },
];

const FaqPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-10 px-2 sm:px-6 lg:px-8 text-left">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-3xl font-extrabold text-center text-gray-800 mb-8">Frequently Asked Questions (FAQ)</h2>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="rounded-lg bg-gray-100 p-6 hover:shadow-md transition-shadow duration-300"
            >
              <h3 className="text-xl font-semibold mb-4">{faq.title}</h3>
              <div className="text-gray-700 text-base">{faq.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqPage;
