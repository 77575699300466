// frontend/src/components/Home.jsx
import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../auth/authcontext';
import SignUpForm from '../components/SignupForm';
import WomenOnCouch from '../assets/images/women-on-couch.png';
import { FaTrophy, FaHandHoldingUsd, FaHandshake } from 'react-icons/fa';
import { GrLineChart } from "react-icons/gr";

function Home() {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard/survey-entry');
    }
  }, [isAuthenticated, navigate]);

  // If authenticated, return null or a loading state
  if (isAuthenticated) {
    return null; // or return <div>Loading...</div>;
  }


  return (
    <div className="p-6 md:p-10 lg:p-20 text-left">
      <h2 className="signup-title">Elevate Your Voice and Earn More</h2>
      <p className="signup-subtitle">You are worth more than you know</p>
      <p className="signup-description">Sign up to earn rewards for completing surveys and other opportunities</p>
      <div className="signup-container">
          {/* SignUp Form */}
          <div className="signup-form-wrapper">
            <SignUpForm />
          </div>

          {/* Wrapper div for background image */}
          <div className="signup-image-container">
            <div className="signup-background-image" />
            {/* div with women on couch background image left of sign up */}
            <div className="signup-content-overlay">
              {/* used to help position the background image above */}
            </div>
          </div>
      </div>

      {/* Four-column row below the top section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="text-center p-4 border rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-2">Level Up</h3>
          <p className="text-gray-600 mb-4">Earn higher incentives as you complete more surveys.</p>
          <GrLineChart className="text-4xl mx-auto" /> 
        </div>
        <div className="text-center p-4 border rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-2">Monthly Bonuses</h3>
          <p className="text-gray-600 mb-4">Top 3 users each month earn a bonus of $200, $300, and $500.</p>
          <FaTrophy className="text-4xl mx-auto" /> 
        </div>
        <div className="text-center p-4 border rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-2">Fair Incentives</h3>
          <p className="text-gray-600 mb-4">Once you reach Level 7, you will earn 60% of partner incentives.</p>
          <FaHandHoldingUsd className="text-4xl mx-auto" /> 
        </div>
        <div className="text-center p-4 border rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-2">Reliable Opportunities</h3>
          <p className="text-gray-600 mb-4">We have partnered with the best. More chances to complete.</p>
          <FaHandshake className="text-4xl mx-auto" /> 
        </div>
      </div>
    </div>
  );
}

export default Home;
