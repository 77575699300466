// frontend/src/components/admin/AdminPanel.jsx
import { Outlet } from 'react-router-dom';
import AdminLayout from './layout/AdminLayout';

const AdminPanel = () => {
    return (
      <AdminLayout>
        <div className="p-6 min-w-0"> {/* Add min-w-0 to prevent flex item expansion */}
          <Outlet />
        </div>
      </AdminLayout>
    );
  };

export default AdminPanel;