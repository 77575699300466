// frontend/src/components/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="text-white py-4 mt-10">
      <div className="container mx-auto text-center">
        <div className="mt-2">
          <Link to="/about" className="mx-2">About</Link> |
          <Link to="/faq" className="mx-2">FAQ</Link> |
          <Link to="/privacy" className="mx-2">Privacy Policy</Link> |
          <Link to="/terms" className="mx-2">Terms of Service</Link>
        </div>
        <p>
          LevelRev &mdash; &copy; <span id="copyright-year">{new Date().getFullYear()}</span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
