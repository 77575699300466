// frontend/src/components/admin/components/ModelForm.jsx
import { useState, useEffect } from 'react';
import Dialog from './ui/Dialog';
import Button from './ui/Button';

const ModelForm = ({ 
  isOpen, 
  onClose, 
  onSubmit, 
  initialData = null,
  fields 
}) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit} className="space-y-4">
        {fields.map((field) => (
          <div key={field.key}>
            <label className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <input
              type={field.type || 'text'}
              value={formData[field.key] || ''}
              onChange={(e) => 
                setFormData({ ...formData, [field.key]: e.target.value })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            />
          </div>
        ))}
        <div className="flex justify-end gap-2">
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            {initialData ? 'Update' : 'Create'}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default ModelForm;