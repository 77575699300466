// frontend/src/pages/Privacy.jsx
import React from 'react';

const PrivacyPage = () => {
    return (
        <div className="privacy-container p-8 max-w-4xl mx-auto text-gray-700 text-left">
        <h1 className="text-3xl font-bold text-center mb-8">Privacy Policy</h1>
        <p className="text-sm text-gray-500 mb-6"><strong>Effective Date:</strong> 10/01/2024</p>

        <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
            <p>Welcome to LevelRev, LLC ("<strong>LevelRev</strong>," "<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"). We are committed to protecting the privacy and security of our users' personal information. This Privacy Policy describes how we collect, use, disclose, and safeguard your information when you visit our website or participate in our services.</p>
        </section>

        <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">Scope</h2>
            <p>This Privacy Policy applies to all visitors, users, and individuals who access or interact with our website and services worldwide, regardless of their location. By accessing or using our services, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">1. Information We Collect</h3>
            <p>We collect various types of information to provide and improve our services to you:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Personal Identification Information:</strong> Includes your name, email address, phone number, mailing address, and any other information you voluntarily provide.</li>
                <li><strong>Demographic Information:</strong> Such as age (18+), gender, interests, occupation, and other information relevant to surveys and research.</li>
                <li><strong>Technical Information:</strong> Your IP address, browser type, operating system, device identifiers, and other technical data.</li>
                <li><strong>Usage Information:</strong> Details about your interactions with our services, including pages viewed, links clicked, and survey responses.</li>
                <li><strong>Transaction Information:</strong> Records of rewards, incentives, and transactions conducted through our services.</li>
                <li><strong>Cookies and Tracking Technologies:</strong> We use cookies, web beacons, and similar technologies to track activity on our services and hold certain information.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">2. How We Use Your Information</h3>
            <p>We use the collected information for various purposes and will only use your information for marketing purposes where you have provided consent or where legally permitted under applicable law:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>To Provide and Maintain Our Services:</strong> Ensuring the functionality and security of our services.</li>
                <li><strong>To Personalize Your Experience:</strong> Tailoring content and offers to your interests.</li>
                <li><strong>To Improve Our Services:</strong> Analyzing usage to enhance features and develop new services.</li>
                <li><strong>To Communicate With You:</strong> Sending updates, newsletters, and marketing materials.</li>
                <li><strong>To Process Transactions:</strong> Managing rewards, incentives, and related transactions.</li>
                <li><strong>To Prevent Fraud and Ensure Security:</strong> Monitoring for security threats and unauthorized activities.</li>
                <li><strong>Legal Basis for Processing (GDPR):</strong> We process your personal data based on consent, contractual necessity, legal obligations, and legitimate interests.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">3. Sharing Your Information</h3>
            <p>We do not sell your personal information. We may share your information in the following circumstances:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>With Service Providers:</strong> Trusted third parties who assist in operating our services (e.g., hosting, email delivery, analytics).</li>
                <li><strong>For Business Transfers:</strong> In connection with mergers, acquisitions, or asset sales.</li>
                <li><strong>For Legal Reasons:</strong> To comply with legal obligations or respond to valid legal requests.</li>
                <li><strong>To Protect Rights and Safety:</strong> When necessary to enforce our policies, protect our rights, property, or safety.</li>
                <li><strong>With Your Consent:</strong> When you provide explicit consent to share your information.</li>
                <li><strong>Aggregated Data:</strong> We may share aggregated or anonymized data that cannot identify you personally.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">4. Cookies and Tracking Technologies</h3>
            <p>We use cookies and similar technologies to enhance your experience:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Types of Cookies Used:</strong>
                    <ul className="list-disc ml-8">
                        <li><strong>Essential Cookies:</strong> Necessary for the operation of our services.</li>
                        <li><strong>Analytical/Performance Cookies:</strong> Help us recognize and count visitors.</li>
                        <li><strong>Functionality Cookies:</strong> Personalize content for you.</li>
                        <li><strong>Targeting Cookies:</strong> Record your visit for advertising purposes.</li>
                    </ul>
                </li>
                <li><strong>Managing Cookies:</strong> We use Cookiebot to manage and obtain user consent for cookie usage. You can modify your preferences for cookie settings via the consent banner or the Cookie Declaration page at any time. However, disabling cookies may affect your ability to use certain features.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">5. Third-Party Links</h3>
            <p>Our services may contain links to third-party websites not operated by us. We have no control over their content or privacy practices. We encourage you to review the privacy policies of any third-party sites you visit.</p>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">6. Data Retention</h3>
            <p>We retain your personal information only as long as necessary for the purposes outlined in this Privacy Policy:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Retention Periods:</strong> Determined based on legal requirements and business needs.</li>
                <li><strong>Deletion:</strong> Upon expiration of the retention period or your written request, we will securely delete or anonymize your data, where applicable, in compliance with legal obligations.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">7. Data Security</h3>
            <p>We implement robust security measures to protect your information:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Technical Measures:</strong> Encryption, secure servers, and firewalls.</li>
                <li><strong>Organizational Measures:</strong> Access controls, staff training, and policies.</li>
                <li><strong>Your Responsibility:</strong> Protect your account credentials and notify us of any unauthorized use.</li>
            </ul>
        </section>



<section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">8. International Data Transfers</h3>
            <p>Your information may be transferred to and processed in countries outside of your own:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>EEA Residents:</strong> We ensure adequate protection by using approved data transfer mechanisms (e.g., Standard Contractual Clauses).</li>
                <li><strong>Consent to Transfer:</strong> By using our services, you consent to the transfer of your information.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">9. Your Data Protection Rights</h3>
            <p>Depending on your location, you have the following rights:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Right to Access:</strong> Request copies of your personal data.</li>
                <li><strong>Right to Rectification:</strong> Correct inaccurate or incomplete data.</li>
                <li><strong>Right to Erasure ("Right to be Forgotten"):</strong> Request deletion of your data under certain conditions.</li>
                <li><strong>Right to Restrict Processing:</strong> Limit how we use your data.</li>
                <li><strong>Right to Data Portability:</strong> Receive your data in a structured, commonly used format.</li>
                <li><strong>Right to Object:</strong> Object to processing based on legitimate interests or direct marketing.</li>
                <li><strong>Right to Withdraw Consent:</strong> At any time, without affecting the lawfulness of processing based on consent before withdrawal.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">10. Children's Privacy</h3>
            <p>Our services are intended for users <strong>18 years of age and older</strong>. We do not knowingly collect personal information from individuals under 18. If we become aware that we have inadvertently collected personal information from a person under 18, we will take immediate steps to delete such information from our records.</p>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">11. Do Not Sell My Personal Information</h3>
            <p>While we do not sell personal information, California residents can formally opt-out:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Opt-Out Requests:</strong> Email us at legal@levelrev.com with the subject "Do Not Sell My Personal Information."</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">12. Consent</h3>
            <p>By using our services, you consent to:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Data Collection and Processing:</strong> As outlined in this Privacy Policy.</li>
                <li><strong>International Transfers:</strong> As necessary for providing our services.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">13. Changes to This Privacy Policy</h3>
            <p>We may update this Privacy Policy periodically:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Notification of Changes:</strong> We will post the new Privacy Policy on this page with an updated effective date.</li>
                <li><strong>Review Periodically:</strong> Please check this page regularly for updates.</li>
            </ul>
        </section>

   
        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">14. Data Protection Officer (DPO)</h3>
            <p>We have appointed a Data Protection Officer to oversee compliance with this Privacy Policy:</p>
            <ul className="list-none ml-4">
                <li><strong>Email:</strong> dpo@levelrev.com</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">15. Accountability and Compliance</h3>
            <p>We are responsible for safeguarding your personal information and complying with applicable privacy laws:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Policies and Training:</strong> We have implemented policies and training to ensure compliance.</li>
                <li><strong>Audits and Monitoring:</strong> Regular audits to monitor compliance.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">16. Opt-Out and Unsubscribe</h3>
            <p>You may opt-out of receiving marketing communications:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Unsubscribe Links:</strong> Provided in marketing emails.</li>
                <li><strong>Contact Us:</strong> Email legal@levelrev.com with your request.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">17. Third-Party Processors</h3>
            <p>We may use third-party service providers to process your data:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Agreements in Place:</strong> We ensure they adhere to data protection laws.</li>
                <li><strong>List of Processors:</strong> Available upon request.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">18. Lawful Basis for Processing Personal Data (GDPR)</h3>
            <p>We rely on the following lawful bases:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Consent:</strong> You have given clear consent.</li>
                <li><strong>Contract:</strong> Processing is necessary for a contract with you.</li>
                <li><strong>Legal Obligation:</strong> Compliance with the law.</li>
                <li><strong>Legitimate Interests:</strong> Processing is necessary for our legitimate interests or those of a third party.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">19. International Users</h3>
            <p>By accessing our services from outside the United States:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Data Transfer:</strong> You consent to the transfer and processing of your data in the United States. We will ensure that such transfers comply with applicable data protection laws and that your data remains protected.</li>
                <li><strong>Privacy Laws:</strong> Be aware that U.S. laws may differ from those in your country.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">20. Data Breach Notification</h3>
            <p>In the event of a data breach:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Notification:</strong> We will notify affected individuals and authorities as required by law.</li>
                <li><strong>Mitigation Efforts:</strong> We will take steps to minimize harm and prevent future breaches.</li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">21. Complaints</h3>
            <p>If you believe we have violated your privacy rights:</p>
            <ul className="list-disc list-inside ml-4">
                <li><strong>Contact Us First:</strong> We encourage you to contact us directly if you have concerns about how we handle your personal information. We will do our best to resolve your complaint quickly and fairly before referring it to a data protection authority.</li>
                <li><strong>Regulatory Authorities:</strong>
                    <ul className="list-disc ml-8">
                        <li><strong>EEA Residents:</strong> You have the right to lodge a complaint with your local data protection authority.</li>
                        <li><strong>Canadian Residents:</strong> Contact the Office of the Privacy Commissioner of Canada.</li>
                        <li><strong>California Residents:</strong> Contact the California Attorney General’s Office.</li>
                    </ul>
                </li>
            </ul>
        </section>

        <section className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Contact Us</h3>
            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us:</p>
            <ul className="list-none ml-4">
                <li><strong>Email:</strong> legal@levelrev.com</li>
                <li><strong>Mail:</strong> LevelRev, LLC, 1111 6th Ave Ste 550, PMB 795943, San Diego, California 92101-5211 US</li>
                <li><strong>Website:</strong> <a href="https://levelrev.com" className="text-blue-500 underline">https://levelrev.com</a></li>
            </ul>
        </section>

        <p className="text-center font-semibold mt-8">Thank you for trusting LevelRev with your personal information. Your privacy is important to us, and we are committed to protecting it.</p>
    </div>
    );
};

export default PrivacyPage;
