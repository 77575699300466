import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../auth/authcontext';
import axios from 'axios';

const Leaderboard = () => {
  const { token, user } = useContext(AuthContext);
  const [topUsers, setTopUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await axios.get('/api/users/leaderboard/', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setTopUsers(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboard();
  }, [token]);

  if (loading) {
    return <p>Loading leaderboard...</p>;
  }

  return (
    <section className="app-card px-4 sm:px-6">
      <div className="space-y-4 mb-6">
        <h1 className="text-2xl font-bold">Leaderboard - Top Users This Month</h1>
        <p>Your rank is highlighted. Ranks are updated daily, so keep going!</p>
        <p className="text-gray-400 text-sm">Note: The leaderboard is updated at the end of each day. Not in real-time</p>
      </div>

      <div className="overflow-auto rounded-lg border border-gray-200 shadow">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Rank</th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Username</th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Level</th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Surveys</th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Bonuses</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {topUsers.length > 0 ? (
              topUsers.map((userStats, index) => (
                <tr 
                  key={index} 
                  className={`${userStats.current_month_rank === 1 ? "bg-green-50" : ""} hover:bg-gray-50`}
                >
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                    {userStats.current_month_rank}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium">
                    {userStats.username}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                    {userStats.user_level}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                    {userStats.total_completes_current_month}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                    ${userStats.total_monthly_bonus_rewards}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="px-3 py-4 text-sm text-gray-500 text-center">
                  No users found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Leaderboard;