import React, { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { AuthContext } from '../auth/authcontext';
import { useMessage } from '../services/MessageService';
import axios from 'axios';

const CintCallback = () => {
  const { token } = useContext(AuthContext);
  const { addMessage } = useMessage();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [processing, setProcessing] = useState(true);
  const processedRef = useRef(false);
  const location = useLocation();

  useEffect(() => {
    let isSubscribed = true;
    const currentPath = location.pathname + location.search;
    const storedPath = sessionStorage.getItem('currentCintCallback');

    // If we've already processed this exact URL, redirect immediately
    if (storedPath === currentPath) {
      navigate('/dashboard/survey-entry', { replace: true });
      return;
    }

    const handleCintCallback = async () => {
      if (processedRef.current) {
        return;
      }

      const params = new URLSearchParams(searchParams);
      const queryString = params.toString();

      if (!queryString.includes('pid') || !queryString.includes('hash')) {
        if (isSubscribed) {
          addMessage('Survey callback failed. Missing required parameters.', 'error');
          navigate('/dashboard/survey-entry', { replace: true });
        }
        return;
      }

      try {
        processedRef.current = true;
        sessionStorage.setItem('currentCintCallback', currentPath);
        
        const storedToken = sessionStorage.getItem('authToken') || token;
        if (!storedToken) {
          addMessage('No authentication token found. Please log in.', 'error');
          navigate('/login', { replace: true });
          return;
        }

        // Add retry logic with delay
        let retryCount = 0;
        const maxRetries = 2;
        
        while (retryCount <= maxRetries) {
          try {
            const response = await axios.get(`/api/integrations/cint-callback/?${queryString}`, {
              headers: { Authorization: `Bearer ${storedToken}` }
            });

            if (response.data.status === 'success') {
              if (isSubscribed) {
                // Check redirect_type to show appropriate message
                const message = response.data.redirect_type === 'Complete' 
                  ? 'Survey Complete Successfully Captured!'
                  : 'Survey Termination Captured';
                addMessage(message, 'success');
                navigate('/dashboard/survey-entry', { replace: true });
              }
              return;
            }
            break;
          } catch (error) {
            if (error.response?.status === 409) {
              // Already processed - just redirect
              navigate('/dashboard/survey-entry', { replace: true });
              return;
            }
            if (retryCount === maxRetries) throw error;
            retryCount++;
            await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
          }
        }

        // If we get here without success or a 409, show error
        addMessage('Failed to process survey callback. Please try again.', 'error');
        navigate('/dashboard/survey-entry', { replace: true });

      } catch (error) {
        if (!isSubscribed) return;

        if (error.response?.status === 401) {
          addMessage('Session expired. Please log in and try again.', 'error');
          navigate('/login', { replace: true });
        } else {
          const errorMessage = error.response?.data?.reason || 'Failed to process survey callback.';
          addMessage(errorMessage, 'error');
          navigate('/dashboard/survey-entry', { replace: true });
        }
      } finally {
        if (isSubscribed) {
          setProcessing(false);
        }
      }
    };

    handleCintCallback();

    return () => {
      isSubscribed = false;
    };
  }, [token, searchParams, navigate, addMessage, location]);

  if (processing) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-4">Processing Survey Completion</h2>
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p className="mt-4 text-gray-600">Please wait while we process your survey completion...</p>
        </div>
      </div>
    );
  }

  return null;
};

export default CintCallback;