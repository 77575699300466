import React from 'react';
import { useRouteError, isRouteErrorResponse, Link } from 'react-router-dom';
import { HomeIcon, AlertCircle, FileQuestion, Ban } from 'lucide-react';
import { cn } from './lib/utils';

const ErrorPage = () => {
  const error = useRouteError();
  
  const getErrorContent = () => {
    if (isRouteErrorResponse(error)) {
      switch (error.status) {
        case 404:
          return {
            title: "Page Not Found",
            message: "Sorry, we couldn't find the page you're looking for.",
            icon: FileQuestion,
            variant: "warning"
          };
        case 401:
          return {
            title: "Unauthorized Access",
            message: "Please log in to access this page.",
            icon: Ban,
            variant: "danger"
          };
        case 403:
          return {
            title: "Access Denied",
            message: "You don't have permission to access this page.",
            icon: Ban,
            variant: "danger"
          };
        default:
          return {
            title: "Something Went Wrong",
            message: "We encountered an unexpected error. Please try again later.",
            icon: AlertCircle,
            variant: "error"
          };
      }
    }
    
    return {
      title: "Unexpected Error",
      message: "We encountered an unexpected error. Please try again later.",
      icon: AlertCircle,
      variant: "error"
    };
  };

  const errorContent = getErrorContent();
  const ErrorIcon = errorContent.icon;

  const alertStyles = {
    base: "rounded-lg p-4 mb-6 border",
    variants: {
      error: "bg-red-50 border-red-200 text-red-800",
      danger: "bg-orange-50 border-orange-200 text-orange-800",
      warning: "bg-yellow-50 border-yellow-200 text-yellow-800"
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[70vh] p-4">
      <div className="w-full max-w-md space-y-6">
        <div className="text-center">
          <ErrorIcon 
            className={cn(
              "mx-auto h-12 w-12 mb-4",
              {
                'text-red-500': errorContent.variant === 'error',
                'text-orange-500': errorContent.variant === 'danger',
                'text-yellow-500': errorContent.variant === 'warning'
              }
            )} 
          />
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            {errorContent.title}
          </h1>
        </div>

        <div 
          className={cn(
            alertStyles.base,
            alertStyles.variants[errorContent.variant]
          )}
        >
          <h2 className="text-lg font-semibold mb-2">
            {errorContent.title}
          </h2>
          <p className="opacity-90">
            {errorContent.message}
          </p>
        </div>

        <div className="text-center space-y-4">
          <p className="text-gray-600">
            If this issue persists, please contact our support team and provide them with the time this occurred.
          </p>
          
          <Link 
            to="/"
            className={cn(
              "inline-flex items-center justify-center gap-2 px-4 py-2",
              "text-sm font-medium text-white bg-blue-600 rounded-md",
              "hover:bg-blue-700 focus:outline-none focus:ring-2",
              "focus:ring-offset-2 focus:ring-blue-500"
            )}
          >
            <HomeIcon className="w-4 h-4" />
            Return Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;