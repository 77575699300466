// frontend/src/features/utmSlice.js
import { createSlice } from '@reduxjs/toolkit';

const utmSlice = createSlice({
  name: 'utm',
  initialState: {
    recruitment_source: 'organic', // default value
    url: '',
    utm_id: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_source_platform: '',
    utm_term: '',
    utm_content: ''
  },
  reducers: {
    setUtmData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setUtmData } = utmSlice.actions;
export default utmSlice.reducer;