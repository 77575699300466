// frontend/src/pages/SignupPage.jsx
import React from 'react';
import SignUpForm from '../components/SignupForm';

function SignupPage() {
  return (
    <div className="max-w-md mx-auto mt-10 bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">Sign Up</h2>
      <SignUpForm />
    </div>
  );
}

export default SignupPage;
