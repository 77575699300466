// frontend/src/components/admin/components/ModelManager.jsx
import { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from './DataTable';
import ModelForm from './ModelForm';
import { toast } from 'react-hot-toast';

const ModelManager = () => {
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const excludedFields = [
    'password',
    'user_permissions',  // too many items to display well
    'groups',           // too many items to display well
  ];

  const getFieldType = (key, value) => {
    if (key.includes('date') || key.includes('_at')) return 'datetime-local';
    if (key.includes('email')) return 'email';
    if (typeof value === 'boolean') return 'checkbox';
    if (typeof value === 'number') return 'number';
    return 'text';
  };

    // Add custom renderers for different data types
  const getColumnConfig = (key) => ({
        key,
        label: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
        render: (value, row) => {
          if (value === null || value === undefined) return '-';
          if (typeof value === 'boolean') return value ? 'Yes' : 'No';
          if (key.includes('date') || key.includes('_at')) {
            return new Date(value).toLocaleString();
          }
          return String(value);
        }
    });

  useEffect(() => {
    fetchModels();
  }, []);

  useEffect(() => {
    if (selectedModel) {
      fetchRecords();
    }
  }, [selectedModel]);

  const fetchModels = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/custom-admin/models/');
      setModels(response.data);
    } catch (error) {
      setError('Failed to fetch models');
    } finally {
      setLoading(false);
    }
  };

  const fetchRecords = async () => {
    if (!selectedModel) return;
    
    try {
      setLoading(true);
      const [app, model] = selectedModel.split('.');
      const response = await axios.get(`/api/custom-admin/models/${app}/${model}/`);
      // Update this line to handle paginated response
      setRecords(response.data.results || response.data); // Handle both paginated and non-paginated responses
    } catch (error) {
      setError('Failed to fetch records');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this record?')) return;
    
    try {
      const [app, model] = selectedModel.split('.');
      await axios.delete(`/api/custom-admin/models/${app}/${model}/${id}/`);
      fetchRecords(); // Refresh the list
      toast.success('Record deleted successfully');
    } catch (error) {
      toast.error('Failed to delete record');
    }
  };

  const handleAdd = () => {
    setEditingRecord(null);
    setIsFormOpen(true);
  };

  const handleEdit = (record) => {
    setEditingRecord(record);
    setIsFormOpen(true);
  };

  const handleSubmit = async (formData) => {
    try {
      const [app, model] = selectedModel.split('.');
      if (editingRecord) {
        await axios.put(
          `/api/custom-admin/models/${app}/${model}/${editingRecord.id}/`,
          formData
        );
        toast.success('Record updated successfully');
      } else {
        await axios.post(`/api/custom-admin/models/${app}/${model}/`, formData);
        toast.success('Record created successfully');
      }
      setIsFormOpen(false);
      fetchRecords();
    } catch (error) {
      toast.error('Operation failed');
    }
  };

  if (loading) return <div className="flex justify-center p-8">Loading...</div>;
  if (error) return <div className="text-red-500 p-8">{error}</div>;

  return (
    <div className="space-y-6 min-w-0">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">Model Management</h2>
        <select 
          onChange={(e) => setSelectedModel(e.target.value)}
          className="px-4 py-2 border rounded-lg"
        >
          <option value="">Select a model</option>
          {models.map(model => (
            <option 
              key={`${model.app_label}.${model.name}`} 
              value={`${model.app_label}.${model.name}`}
            >
              {model.verbose_name}
            </option>
          ))}
        </select>
      </div>
      


      {selectedModel && records && records.length > 0 && (
        <DataTable
            data={records}
            columns={Object.keys(records[0] || {})
            .filter(key => !excludedFields.includes(key))
            .map(key => getColumnConfig(key))}  // Use getColumnConfig here
            onEdit={handleEdit}
            onDelete={handleDelete}
            onAdd={handleAdd}
            isLoading={loading}
        />
        )}

      <ModelForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        onSubmit={handleSubmit}
        initialData={editingRecord}
        fields={
          selectedModel && records.length > 0
            ? Object.keys(records[0] || {}).map(key => ({
                key,
                label: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
                type: 'text'
              }))
            : []
        }
      />
    </div>
  );
};

export default ModelManager;