// Gender Choices
export const GenderChoices = {
    1: 'Male',
    2: 'Female',
    3: 'Non-Binary / Gender Non-conforming / Another identity not listed',
};

// Household Income (HHI) Choices
export const HHIChoices = {
    1: 'Less than $5,000',
    2: '$5,000 to $9,999',
    3: '$10,000 to $14,999',
    4: '$15,000 to $19,999',
    5: '$20,000 to $24,999',
    6: '$25,000 to $29,999',
    7: '$30,000 to $34,999',
    8: '$35,000 to $39,999',
    9: '$40,000 to $44,999',
    10: '$45,000 to $49,999',
    11: '$50,000 to $54,999',
    12: '$55,000 to $59,999',
    13: '$60,000 to $64,999',
    14: '$65,000 to $69,999',
    15: '$70,000 to $74,999',
    16: '$75,000 to $79,999',
    17: '$80,000 to $84,999',
    18: '$85,000 to $89,999',
    19: '$90,000 to $94,999',
    20: '$95,000 to $99,999',
    21: '$100,000 to $124,999',
    22: '$125,000 to $149,999',
    23: '$150,000 to $174,999',
    24: '$175,000 to $199,999',
    25: '$200,000 to $249,999',
    26: '$250,000 to $499,999',
    27: '$500,000 to $999,999',
    28: '$1 million +',
    30: 'Prefer not to answer',
};

// Education Choices
export const EducationChoices = {
    1: 'Elementary School',
    2: 'Middle School',
    3: 'Completed some high school',
    4: 'High school graduate',
    5: 'Other post high school vocational training',
    6: 'Completed some college',
    7: 'Associates Degree',
    8: "Bachelor's degree",
    9: 'Completed some postgraduate',
    10: 'Masters degree',
    11: 'Doctorate /PhD',
    12: 'None of the above/Prefer not to answer',
};

// Marital Status Choices
export const MaritalStatusChoices = {
    1: 'Single, never married',
    2: 'Married',
    3: 'Separated, divorced or widowed',
    4: 'Domestic partnership/living with someone',
    5: 'Prefer not to answer',
    6: 'Engaged',
};


// Ethnicity Choices
export const EthnicityChoices = {
    1: 'White',
    2: 'Black or African American',
    3: 'Hispanic',
    4: 'American Indian or Alaska Native',
    9: 'Middle Eastern or North African',
    5: 'Asian',
    6: 'Pacific Islander',
    7: 'Some other race',
    8: 'Prefer not to answer',
};

// Race Choices
export const RaceChoices = {
    1: 'White',
    2: 'Black or African American',
    3: 'American Indian or Alaska Native',
    4: 'Asian - Asian Indian',
    5: 'Asian - Chinese',
    6: 'Asian - Filipino',
    7: 'Asian - Japanese',
    8: 'Asian - Korean',
    9: 'Asian - Vietnamese',
    10: 'Asian - Other',
    11: 'Pacific Islander - Native Hawaiian',
    12: 'Pacific Islander - Guamanian',
    13: 'Pacific Islander - Samoan',
    14: 'Pacific Islander - Other Pacific Islander',
    15: 'Middle Eastern or North African',
    16: 'Some other race',
    17: 'Prefer not to answer',
};

// Hispanic Origin Choices
export const HispanicOriginChoices = {
    1: 'Not Hispanic',
    2: 'Yes, from Mexico',
    3: 'Yes, from Cuba',
    6: 'Yes, from Argentina',
    7: 'Yes, from Colombia',
    8: 'Yes, from Ecuador',
    9: 'Yes, from El Salvador',
    10: 'Yes, from Guatemala',
    11: 'Yes, from Nicaragua',
    12: 'Yes, from Panama',
    13: 'Yes, from Peru',
    14: 'Yes, Puerto Rican',
    15: 'Yes, from Venezuela',
    16: 'Yes, from Spain',
    17: 'Yes, Other',
    18: 'Prefer not to answer',

};

// Employment Status Choices
export const EmploymentStatusChoices = {
    1: 'Employed full-time',
    2: 'Employed part-time',
    3: 'Self-employed full-time',
    4: 'Self-employed part-time',
    5: 'Active military',
    6: 'Inactive military/Veteran',
    7: 'Temporarily unemployed',
    8: 'Full-time homemaker',
    9: 'Retired',
    10: 'Student',
    11: 'Disabled',
    12: 'Prefer not to answer',
    13: 'Parental leave',
    14: 'Leave of absence',
    15: 'Unable to work',
    16: 'Other type of paid work',
};


// Personal Industry Choices
export const PersonalIndustryChoices = {
    1: 'Accounting',
    2: 'Advertising',
    3: 'Agriculture/Fishing',
    4: 'Architecture',
    5: 'Automotive',
    6: 'Aviation',
    7: 'Banking/Financial',
    8: 'Bio-Tech',
    9: 'Brokerage',
    10: 'Carpentry/Electrical installations',
    11: 'Chemicals/Plastics/Rubber',
    12: 'Communications/Information',
    13: 'Computer Hardware',
    14: 'Computer Reseller (software/hardware)',
    15: 'Computer Software',
    16: 'Construction',
    17: 'Consulting',
    18: 'Consumer Electronics',
    19: 'Consumer Packaged Goods',
    20: 'Education',
    21: 'Energy/Utilities/Oil and Gas',
    22: 'Engineering',
    23: 'Environmental Services',
    24: 'Fashion/Apparel',
    25: 'Food/Beverage',
    26: 'Government/Public Sector',
    27: 'Healthcare',
    28: 'Hospitality/Tourism',
    29: 'Human Resources',
    30: 'Information Technology/IT',
    31: 'Insurance',
    32: 'Internet',
    33: 'Legal/Law',
    34: 'Manufacturing',
    35: 'Market Research',
    37: 'Media/Entertainment',
    38: 'Military',
    39: 'Non Profit/Social services',
    40: 'Personal Services',
    41: 'Pharmaceuticals',
    42: 'Printing Publishing',
    43: 'Public Relations',
    44: 'Real Estate/Property',
    45: 'Retail/Wholesale trade',
    46: 'Security',
    47: 'Shipping/Distribution',
    48: 'Telecommunications',
    49: 'Transportation',
    50: 'Other',
    51: "I don't work",
    52: 'Marketing',
    53: 'Sales'
};


// Age Gender Children Choices
export const AgeGenderChildrenChoices = {
    37: 'None of the above',
    1: 'Boy under age 1',
    2: 'Girl under age 1',
    3: 'Boy age 1',
    4: 'Girl age 1',
    5: 'Boy age 2',
    6: 'Girl age 2',
    7: 'Boy age 3',
    8: 'Girl age 3',
    9: 'Boy age 4',
    10: 'Girl age 4',
    11: 'Boy age 5',
    12: 'Girl age 5',
    13: 'Boy age 6',
    14: 'Girl age 6',
    15: 'Boy age 7',
    16: 'Girl age 7',
    17: 'Boy age 8',
    18: 'Girl age 8',
    19: 'Boy age 9',
    20: 'Girl age 9',
    21: 'Boy age 10',
    22: 'Girl age 10',
    23: 'Boy age 11',
    24: 'Girl age 11',
    25: 'Boy age 12',
    26: 'Girl age 12',
    27: 'Male teen age 13',
    28: 'Female teen age 13',
    29: 'Male teen age 14',
    30: 'Female teen age 14',
    31: 'Male teen age 15',
    32: 'Female teen age 15',
    33: 'Male teen age 16',
    34: 'Female teen age 16',
    35: 'Male teen age 17',
    36: 'Female teen age 17',
};

// Parental Status Choices
export const ParentalStatusChoices = {
    1: 'I am pregnant/expecting a child within the next 9 months',
    2: 'I have one or more children under the age of 18 living in my household',
    3: 'I have one or more children over the age of 18 living in my household',
    4: 'I have no children living in my household and I am not pregnant/expecting a child within the next 9 months',
};

export const CountryChoices = {
    1: 'United States',
    // 2: 'Canada',
    // 3: 'United Kingdom',
    // 4: 'Australia',
    // 5: 'Germany',
    // 6: 'France',
    // 7: 'Brazil',
    // 8: 'China',
    // 9: 'India',
    // 10: 'Italy',
    // 11: 'Japan',
    // 12: 'Mexico',
    // 13: 'Russia',
    // 14: 'South Korea',
    // 15: 'Spain',
    // 16: 'Other',

};
