import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const AdminAnalytics = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('growth');
  const [filters, setFilters] = useState({
    date_range: '30d',
  });

  const tabs = [
    { id: 'growth', label: 'User Growth' },
    { id: 'revenue', label: 'Revenue & Redemptions' },
    { id: 'performance', label: 'Platform Performance' },
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      const params = new URLSearchParams(filters);
      const response = await axios.get(`/api/custom-admin/analytics/?${params}`);
      setData(response.data);
    } catch (error) {
      setError(error.response?.data?.detail || 'Failed to fetch analytics data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  const renderGrowthTab = () => (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>User Signups</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data?.signups.daily_signups}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="date" 
                tickFormatter={(date) => new Date(date).toLocaleDateString()}
                label={{ value: 'Date', position: 'bottom', offset: 0 }}
              />
              <YAxis 
                label={{ value: 'Number of Signups', angle: -90, position: 'left', offset: 10 }}
              />
                <Tooltip 
                  labelFormatter={(date) => new Date(date).toLocaleDateString()}
                  formatter={(value) => [`${value} signups`]}
                />
                <Line 
                  type="monotone" 
                  dataKey="count" 
                  stroke="#2563eb" 
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>Sign Up Sources</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Source
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Users
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      %
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data?.signups.sources.map((source) => (
                    <tr key={source.source}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {source.source}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {source.count}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {source.percentage}%
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Growth Summary</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              <div>
                <div className="text-sm font-medium text-gray-500">Total Signups</div>
                <div className="mt-1 text-2xl font-semibold">
                  {data?.signups.total_signups}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Daily Average</div>
                <div className="mt-1 text-2xl font-semibold">
                  {Math.round(data?.signups.total_signups / data?.signups.daily_signups.length)}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );

  const renderRevenueTab = () => (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Redemption Activity</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data?.redemptions.daily_data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="date" 
                tickFormatter={(date) => new Date(date).toLocaleDateString()}
                label={{ value: 'Date', position: 'bottom', offset: 0 }}
              />
              <YAxis 
                label={{ value: 'Amount ($)', angle: -90, position: 'left', offset: 10 }}
              />
                <Tooltip 
                  labelFormatter={(date) => new Date(date).toLocaleDateString()}
                  formatter={(value) => [`$${value}`, 'Amount']}
                />
                <Line 
                  type="monotone" 
                  dataKey="total_amount" 
                  stroke="#2563eb" 
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>Recent Transactions</CardTitle>
          </CardHeader>
          <CardContent>
            {!data?.redemptions.recent_transactions?.length ? (
              <div className="text-center py-8 text-gray-500">
                No Transaction Data Available
              </div>
            ) : (
              <table className="min-w-full divide-y divide-gray-200">
                {/* Table head remains the same */}
                <tbody className="bg-white divide-y divide-gray-200">
                  {data?.redemptions.recent_transactions?.map((tx) => (
                    <tr key={tx.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {tx.created_at ? new Date(tx.created_at).toLocaleDateString() : 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        ${tx.amount?.toFixed(2) || '0.00'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {tx.payout_status || 'Unknown'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Revenue Metrics</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              <div>
                <div className="text-sm font-medium text-gray-500">Total Redemptions</div>
                <div className="mt-1 text-2xl font-semibold">
                  ${data?.redemptions.total_amount}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Average Transaction</div>
                <div className="mt-1 text-2xl font-semibold">
                  ${(data?.redemptions.total_amount / data?.redemptions.total_count || 0).toFixed(2)}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );

  const renderPerformanceTab = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>User Level Distribution</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
              <BarChart data={data?.user_performance.level_distribution}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="user_level"
                  label={{ value: 'User Level', position: 'bottom', offset: 0 }}
                />
                <YAxis 
                  label={{ value: 'Number of Users', angle: -90, position: 'left', offset: 10 }}
                />
                <Tooltip />
                <Bar dataKey="count" fill="#2563eb" />
              </BarChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Survey Metrics</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              <div>
                <div className="text-sm font-medium text-gray-500">Average CPI</div>
                <div className="mt-1 text-2xl font-semibold">
                  ${data?.survey_metrics.avg_cpi}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Total Surveys Completed</div>
                <div className="mt-1 text-2xl font-semibold">
                  {data?.user_performance.total_completions}
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500">Average User Level</div>
                <div className="mt-1 text-2xl font-semibold">
                  {data?.user_performance.avg_level}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="p-4 border-b space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex space-x-4">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors ${
                  activeTab === tab.id
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                {tab.label}
              </button>
            ))}
          </div>
          <select
            className="rounded-md border border-gray-300 p-2"
            value={filters.date_range}
            onChange={(e) => setFilters({ ...filters, date_range: e.target.value })}
          >
            <option value="7d">Last 7 Days</option>
            <option value="30d">Last 30 Days</option>
            <option value="90d">Last 90 Days</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="text-gray-500">Loading...</div>
        </div>
      ) : (
        <div className="p-4">
          {activeTab === 'growth' && renderGrowthTab()}
          {activeTab === 'revenue' && renderRevenueTab()}
          {activeTab === 'performance' && renderPerformanceTab()}
        </div>
      )}
    </div>
  );
};

export default AdminAnalytics;