// src/components/ui/combobox.jsx
import React, { useState } from "react"
import { ChevronsUpDown } from "lucide-react"
import { cn } from "../../lib/utils"

const MultiSelect = ({ options, value = [], onChange, placeholder = "Select options..." }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")

  const selectedLabels = Object.entries(options)
    .filter(([val]) => value.includes(val))
    .map(([_, label]) => label)
    .join(", ")

  const filteredOptions = Object.entries(options).filter(([_, label]) =>
    label.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className="relative w-full">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between p-3 text-left bg-white border rounded-lg shadow-sm"
      >
        <span className="truncate">
          {selectedLabels || placeholder}
        </span>
        <ChevronsUpDown className="h-4 w-4 opacity-50" />
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg">
          <div className="p-2">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 border rounded-md"
              onClick={(e) => e.stopPropagation()}
            />
          </div>

          <div className="max-h-[300px] overflow-y-auto p-2">
            {filteredOptions.map(([optionValue, label]) => (
              <label
                key={optionValue}
                className="flex items-center gap-2 px-2 py-1.5 hover:bg-gray-100 cursor-pointer rounded"
              >
                <input
                  type="checkbox"
                  checked={value.includes(optionValue)}
                  onChange={() => {
                    const newValue = value.includes(optionValue)
                      ? value.filter(v => v !== optionValue)
                      : [...value, optionValue];
                    onChange(newValue);
                  }}
                  className="h-4 w-4 rounded border-gray-300"
                />
                <span className="text-sm">{label}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export { MultiSelect }