// frontend/src/components/appnav.jsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const AppNav = ({ items, ...props }) => {
  const location = useLocation();

  return (
    <ul className="menu p-2 rounded-box mt-14" {...props}>
      {items.map((item) => (
        <li key={item.href}>
          <Link
            to={item.href}
            className={`flex items-center ${
              item.fuzzyMatchActivePath
                ? location.pathname.startsWith(item.fuzzyMatchActivePath)
                  ? "active bg-primary text-white"
                  : ""
                : location.pathname === item.href
                ? "active bg-primary text-white"
                : ""
            }`}
          >
            <span className="mr-2">{item.icon}</span>
            <span>{item.title}</span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default AppNav;