// frontend/src/components/UtmCapture.jsx
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUtmData } from '../features/utmSlice';

function UtmCapture() {
  const dispatch = useDispatch();

  useEffect(() => {
    const captureUtmParams = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const currentUrl = window.location.href;
      
      const utmData = {
        url: currentUrl,
        utm_id: urlParams.get('utm_id') || '',
        utm_source: urlParams.get('utm_source') || '',
        utm_medium: urlParams.get('utm_medium') || '',
        utm_campaign: urlParams.get('utm_campaign') || '',
        utm_source_platform: urlParams.get('utm_source_platform') || '',
        utm_term: urlParams.get('utm_term') || '',
        utm_content: urlParams.get('utm_content') || '',
        recruitment_source: urlParams.get('ref') || 'organic'
      };

      // Store in both Redux and localStorage
      dispatch(setUtmData(utmData));
      localStorage.setItem('utmData', JSON.stringify(utmData));
    };

    // Check localStorage first
    const storedUtmData = localStorage.getItem('utmData');
    if (storedUtmData) {
      dispatch(setUtmData(JSON.parse(storedUtmData)));
    }

    // Then check URL parameters
    captureUtmParams();
  }, [dispatch]);

  return null;
}

export default UtmCapture;