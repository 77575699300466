// frontend/src/pages/About.jsx
import React from 'react';

const AboutPage = () => {
    return (
        <div className="privacy-container p-8 max-w-4xl mx-auto text-gray-700">
            <h1 className="text-3xl font-bold text-center mb-8">About</h1>

            <section className="mb-6">
                <p>
                    We are dedicated to fairly incentivizing our users for their honest participation. We believe that when users put in the effort, they deserve competitive rewards. Our incentive structure is designed to ensure that users get the most value we can offer.
                </p>
            </section>
        </div>
    );
};

export default AboutPage;