// frontend/src/components/admin/layout/AdminLayout.jsx
import { Sidebar } from '../components/Sidebar';
import { TopBar } from '../components/TopBar';

const AdminLayout = ({ children }) => (
  <div className="min-h-screen bg-gray-100">
    <TopBar />
    <div className="flex">
      <Sidebar />
      <main className="flex-1 min-w-0"> {/* Add min-w-0 */}
        {children}
      </main>
    </div>
  </div>
);

export default AdminLayout;