// frontend/src/pages/Terms.tsx
import React from 'react';

const TermsPage = () => {
    return (
        <div className="terms-container p-8 max-w-4xl mx-auto text-gray-700 text-left">
            <h1 className="text-3xl font-bold text-center mb-8">Terms of Service</h1>
            <p className="text-sm text-gray-500 mb-6">
                Welcome to LevelRev, LLC! These terms of service outline the rules and regulations for the use of our website, located at <a href="https://levelrev.com" className="text-blue-500 underline">https://levelrev.com</a>. By accessing this website, we assume you accept these terms and conditions. Do not continue to use LevelRev, LLC if you do not agree to all of the terms and conditions stated on this page.
            </p>

            <section className="mb-6">
                <h3 className="text-2xl font-semibold mb-4">1. Introduction</h3>
                <p>These terms and conditions govern your use of our website and services. By using our website, you accept these terms in full. If you disagree with any part of these terms, you must not use our website.</p>
            </section>

            <section className="mb-6">
                <h3 className="text-2xl font-semibold mb-4">2. Eligibility</h3>
                <p>You must be at least 18 years of age to use our website and services. By using our website and agreeing to these terms, you represent and warrant that you are at least 18 years of age.</p>
            </section>

            <section className="mb-6">
                <h3 className="text-2xl font-semibold mb-4">3. User Account</h3>
                <p>To access certain features of our website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device. You agree to accept responsibility for all activities that occur under your account or password.</p>
            </section>

            <section className="mb-6">
                <h3 className="text-2xl font-semibold mb-4">4. Use of Services</h3>
                <p>By using our services, you agree to use them only for lawful purposes. You must not use our services in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website. You must not use our services in any way which is unlawful, illegal, fraudulent, or harmful.</p>
            </section>

            <section className="mb-6">
                <h3 className="text-2xl font-semibold mb-4">5. Intellectual Property</h3>
                <p>Unless otherwise stated, LevelRev, LLC and/or its licensors own the intellectual property rights for all material on this website. All intellectual property rights are reserved. You may view and/or print pages from <a href="https://levelrev.com" className="text-blue-500 underline">https://levelrev.com</a> for your own personal use subject to restrictions set in these terms and conditions.</p>
            </section>

            <section className="mb-6">
                <h3 className="text-2xl font-semibold mb-4">6. User-Generated Content</h3>
                <p>Certain parts of this website offer the opportunity for users to post and exchange opinions, information, material, and data ('User Content'). LevelRev, LLC does not screen, edit, publish, or review User Content prior to its appearance on the website, and User Content does not reflect the views and opinions of LevelRev, LLC, its agents, or affiliates.</p>
            </section>

            <section className="mb-6">
                <h3 className="text-2xl font-semibold mb-4">7. Termination</h3>
                <p>We may terminate or suspend your account and access to our services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms. Upon termination, your right to use the services will immediately cease.</p>
            </section>

            <section className="mb-6">
                <h3 className="text-2xl font-semibold mb-4">8. Limitation of Liability</h3>
                <p>In no event shall LevelRev, LLC, nor any of its officers, directors, and employees, be liable to you for anything arising out of or in any way connected with your use of this website.</p>
            </section>

            <section className="mb-6">
                <h3 className="text-2xl font-semibold mb-4">9. Indemnification</h3>
                <p>You hereby indemnify to the fullest extent LevelRev, LLC from and against any and all liabilities, costs, demands, causes of action, damages, and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these terms.</p>
            </section>

            <section className="mb-6">
                <h3 className="text-2xl font-semibold mb-4">10. Changes to Terms</h3>
                <p>LevelRev, LLC reserves the right to revise these terms at any time as it sees fit, and by using this website, you are expected to review these terms on a regular basis to ensure you understand all terms and conditions governing the use of this website.</p>
            </section>

            <section className="mb-6">
                <h3 className="text-2xl font-semibold mb-4">11. Governing Law</h3>
                <p>These terms will be governed by and interpreted in accordance with the laws of California, United States of America, and you submit to the non-exclusive jurisdiction of the state and federal courts located in California, United States of America for the resolution of any disputes.</p>
            </section>

            <section className="mb-6">
                <h3 className="text-2xl font-semibold mb-4">12. Gamification Rules</h3>

                <h4 className="text-xl font-semibold mb-2">General Participation Rules</h4>
                <p><b>1. Daily Survey Completion Requirement:</b></p>
                <ul className="list-disc list-inside ml-4">
                    <li>Users must complete at least one survey per day to be eligible for the end-of-month bonus. For example, if there are 30 days in a month, a user must complete a minimum of 30 surveys in that month. Users are encouraged to complete more surveys if they wish.</li>
                </ul>

                <p><b>2. User Conduct:</b></p>
                <ul className="list-disc list-inside ml-4">
                    <li>Users must adhere to our community guidelines and avoid any behavior that could result in a ban. If a user is banned, all accumulated points and incentives are forfeited. Common reasons for banning include attempts to manipulate our systems, such as falsely claiming to be from the United States while residing in another country, using VPNs to mask your location, employing bots to complete surveys, or rushing through surveys, which compromises data quality. Engaging in these activities leads to the submission of false and poor-quality survey data, undermining the integrity of our platform.</li>
                </ul>

                <p><b>3. Leveling Up:</b></p>
                <ul className="list-disc list-inside ml-4">
                    <li>Users advance through levels by completing a specified number of surveys:</li>
                    <ul className="list-disc list-inside ml-8">
                        <li><b>Level 2</b>: 20 survey completions</li>
                        <li><b>Level 3</b>: 40 survey completions</li>
                        <li><b>Level 4</b>: 60 survey completions</li>
                        <li><b>Level 5</b>: 80 survey completions</li>
                        <li><b>Level 6</b>: 100 survey completions</li>
                        <li><b>Level 7</b>: 120 survey completions</li>
                    </ul>
                </ul>

                <p><b>4. Rewarding Honesty:</b></p>
                <ul className="list-disc list-inside ml-4">
                    <li>To ensure the quality and honesty of responses, the distribution of monthly winners' rewards will be delayed by 20 days from the end of each month. For example, winners for January will receive their rewards on February 20th. This delay allows our system to identify and correct any reversals or reconciliations. Survey completions deemed fraudulent or unacceptable by our research partners will not count towards monthly totals. For instance, if a user completes 30 surveys in a month and 2 of those are reconciled, their total for that month will be adjusted to 28 surveys.</li>
                    <li>Similarly, incentives paid for survey rewards will be available 20 days after a user completes a given survey. This delay allows our system to identify and correct any reversals or reconciliations. Survey completions deemed fraudulent or unacceptable by our research partners will be deducted from your surveys rewards.</li>
                </ul>

                

                <h4 className="text-xl font-semibold mt-6 mb-2">Incentive Structure</h4>
                <p><b>1. Per-Survey Incentives:</b></p>
                <ul className="list-disc list-inside ml-4">
                    <li>Users are paid incentives for each survey they complete, with the amount increasing as they progress through levels:</li>
                    <ul className="list-disc list-inside ml-8">
                        <li><b>Level 1</b>: 30% of partner incentive</li>
                        <li><b>Level 2</b>: 35% of partner incentive</li>
                        <li><b>Level 3</b>: 40% of partner incentive</li>
                        <li><b>Level 4</b>: 45% of partner incentive</li>
                        <li><b>Level 5</b>: 50% of partner incentive</li>
                        <li><b>Level 6</b>: 55% of partner incentive</li>
                        <li><b>Level 7</b>: 60% of partner incentive</li>
                    </ul>
                </ul>

                <p><b>2. Monthly Bonus Rewards:</b></p>
                <ul className="list-disc list-inside ml-4">
                    <li>The top 3 users who complete the most surveys each month will receive additional bonus rewards:</li>
                    <ul className="list-disc list-inside ml-8">
                        <li><b>1st Place</b>: $500</li>
                        <li><b>2nd Place</b>: $300</li>
                        <li><b>3rd Place</b>: $200</li>
                    </ul>
                </ul>
            </section>

            <section className="mt-8">
                <p className="font-semibold">
                    Thank you for reading our Terms of Service. If you have any questions or concerns, please contact us at <a href="mailto:legal@levelrev.com" className="text-blue-500 underline">legal@levelrev.com</a>.
                </p>
            </section>
        </div>
    );
};

export default TermsPage;