// frontend/src/components/Messages.jsx
import React from 'react';
import { useMessage } from '../services/MessageService';

const Messages = () => {
  const { messages, removeMessage } = useMessage();

  return (
    <div className="fixed top-4 right-4 space-y-2 z-50">
      {messages.map((message) => (
        <div
          key={message.id}
          className={`border rounded-md p-4 shadow-md flex items-center justify-between ${
            message.type === 'success' ? 'bg-green-200 border-green-700' :
            message.type === 'error' ? 'bg-red-200 border-red-700' :
            message.type === 'info' ? 'bg-blue-200 border-blue-700' : 
            'bg-yellow-200 text-black border-yellow-700'
          }`}
        >
          <span>{message.content}</span>
          <button
            onClick={() => removeMessage(message.id)}
            className="ml-4 text-xl font-semibold leading-none focus:outline-none"
          >
            &times;
          </button>
        </div>
      ))}
    </div>
  );
};

export default Messages;