// frontend/src/components/admin/components/ui/Card.jsx
const Card = ({ children, className = '' }) => {
    return (
      <div className={`bg-white rounded-lg shadow ${className}`}>
        {children}
      </div>
    );
  };
  
  const CardHeader = ({ children, className = '' }) => {
    return (
      <div className={`p-4 border-b ${className}`}>
        {children}
      </div>
    );
  };
  
  const CardTitle = ({ children, className = '' }) => {
    return (
      <h3 className={`text-lg font-semibold ${className}`}>
        {children}
      </h3>
    );
  };
  
  const CardContent = ({ children, className = '' }) => {
    return (
      <div className={`p-4 ${className}`}>
        {children}
      </div>
    );
  };
  
  export { Card, CardHeader, CardTitle, CardContent };