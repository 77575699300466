import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../auth/authcontext';
import { useMessage } from '../services/MessageService';
import axios from 'axios';

const PayPalCallback = () => {
  const { token } = useContext(AuthContext);
  const { addMessage } = useMessage();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    let isSubscribed = true; // Add cleanup flag

    const handlePayPalCallback = async () => {
      const code = searchParams.get('code');
      
      if (!code) {
        if (isSubscribed) {
          addMessage("PayPal connection failed. Please try again.", "error");
          navigate('/dashboard/redeem-rewards');
        }
        return;
      }

      try {
        const storedToken = sessionStorage.getItem('authToken') || token;
        
        const response = await axios.get('/api/redemptions/paypal-callback/', {
          params: { code },
          headers: { Authorization: `Bearer ${storedToken}` }
        });

        if (isSubscribed) { // Check flag before updating state or navigating
          if (response.data.success) {
            addMessage("PayPal account successfully connected!", "success");
            sessionStorage.removeItem('authToken');
            navigate('/dashboard/redeem-rewards');
          } else {
            addMessage("Failed to connect PayPal account. Please try again.", "error");
            navigate('/dashboard/redeem-rewards');
          }
        }
      } catch (error) {
        if (isSubscribed) { // Check flag before error handling
          if (error.response?.status === 401) {
            addMessage("Session expired. Please log in and try again.", "error");
            navigate('/login');
          } else {
            const errorMessage = error.response?.data?.error || "Failed to connect PayPal account. Please try again.";
            addMessage(errorMessage, "error");
            navigate('/dashboard/redeem-rewards');
          }
        }
      } finally {
        if (isSubscribed) {
          setProcessing(false);
          sessionStorage.removeItem('authToken');
        }
      }
    };

    handlePayPalCallback();

    // Cleanup function
    return () => {
      isSubscribed = false;
    };
  }, []); // Remove dependencies that might cause re-renders

  if (processing) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-4">Connecting PayPal Account</h2>
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p className="mt-4 text-gray-600">Please wait while we connect your PayPal account...</p>
        </div>
      </div>
    );
  }

  return null;
};

export default PayPalCallback;