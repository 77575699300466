// frontend/src/components/admin/components/ui/Table.jsx
import React from 'react';

export const Table = ({ children, className = "" }) => (
  <div className="overflow-x-auto shadow ring-1 ring-black ring-opacity-5">
    <table className={`min-w-full divide-y divide-gray-300 ${className}`}>
      {children}
    </table>
  </div>
);

export const TableHead = ({ children, className = "" }) => (
  <thead className={`bg-gray-50 ${className}`}>
    {children}
  </thead>
);

export const TableBody = ({ children, className = "" }) => (
  <tbody className={`bg-white divide-y divide-gray-200 ${className}`}>
    {children}
  </tbody>
);

export const TableRow = ({ children, className = "" }) => (
  <tr className={`${className}`}>
    {children}
  </tr>
);

export const TableCell = ({ children, className = "", onClick, colSpan }) => (
  <td 
    className={`px-3 py-2 text-sm ${className}`}
    onClick={onClick}
    colSpan={colSpan}
  >
    <div className="max-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
      {children}
    </div>
  </td>
);