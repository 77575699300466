import React from 'react';

const surveyRules = [
  {
    title: "Daily Survey Completion Requirement",
    content: (
      <p>
        Users must complete at least one survey per day to be eligible for the end-of-month bonus, so daily participation is highly recommended. However, if you complete an average of at least one survey per day, you are still eligible. For example, in a 30-day month, you must complete a minimum of 30 surveys. You are always welcome to complete more surveys if you like. Remember, bonus payouts are only given to the top three users each month. Completing one survey per day is the minimum requirement.
      </p>
    ),
  },
  {
    title: "User Conduct",
    content: (
      <p>
        Users must adhere to our community guidelines and avoid any behavior that could result in a ban. If a user is banned, all accumulated points and incentives are forfeited. Common reasons for banning include attempts to manipulate our systems, such as falsely claiming to be from the United States while residing in another country, using VPNs to mask your location, employing bots to complete surveys, or rushing through surveys, which compromises data quality. Engaging in these activities leads to the submission of false and poor-quality survey data, undermining the integrity of our platform.
      </p>
    ),
  },
  {
    title: "Leveling Up",
    content: (
      <>
        <p>
          When users complete surveys, they are rewarded with an incentive. To be clear, completing a survey means the user qualified for and fully completed the survey. Being screened out or disqualified does not count as a complete. The incentive earned for completing a survey is based on the user's level. Users advance through levels by completing a specified number of surveys. Everyone starts at level 1, and after completing 20 surveys, they advance to level 2. Below, we have detailed the number of surveys needed to reach each level. Note that the highest level a user can achieve is level 7.
        </p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li><b>Level 2</b>: 20 survey completions</li>
          <li><b>Level 3</b>: 40 survey completions</li>
          <li><b>Level 4</b>: 60 survey completions</li>
          <li><b>Level 5</b>: 80 survey completions</li>
          <li><b>Level 6</b>: 100 survey completions</li>
          <li><b>Level 7</b>: 120 survey completions</li>
        </ul>
      </>
    ),
  },
  {
    title: "Rewarding Honesty",
    content: (
      <p>
        To ensure the quality and honesty of responses, the distribution of the end-of-month bonus will be delayed by 20 days from the end of each month. For example, winners for January will receive their rewards on February 20th. This delay allows our system enough time to identify and correct any reversals or reconciliations. Survey completions deemed fraudulent or unacceptable by our research partners may not count towards monthly totals. For instance, if a user completes 30 surveys in a month and 2 of those are reconciled, their total for that month will be adjusted to 28 surveys.
      </p>
    ),
  },
  {
    title: "Per-Survey Incentives",
    content: (
      <>
        <p>Users are paid incentives for each survey they complete, with the amount increasing as they progress through levels:</p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li><b>Level 1</b>: 30% of partner incentive</li>
          <li><b>Level 2</b>: 35% of partner incentive</li>
          <li><b>Level 3</b>: 40% of partner incentive</li>
          <li><b>Level 4</b>: 45% of partner incentive</li>
          <li><b>Level 5</b>: 50% of partner incentive</li>
          <li><b>Level 6</b>: 55% of partner incentive</li>
          <li><b>Level 7</b>: 60% of partner incentive</li>
        </ul>
      </>
    ),
  },
  {
    title: "Monthly Bonus Rewards",
    content: (
      <>
        <p>The top 3 users who complete the most surveys each month will receive additional bonus rewards:</p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li><b>1st Place</b>: $500</li>
          <li><b>2nd Place</b>: $300</li>
          <li><b>3rd Place</b>: $200</li>
        </ul>
      </>
    ),
  },
  {
    title: "Important Information About End-of-Month Bonuses",
    content: (
      <>
        <p>To ensure the <strong>quality and honesty</strong> of all survey responses, we have implemented a 20-day delay in distributing end-of-month bonuses. This means bonuses will be paid out <strong>20 days after the end of each month</strong>.</p>
        <p className="mt-2"><strong>For example</strong>: If you earn a bonus in January, you will receive your reward on <strong>February 20th</strong>.</p>
        <h4 className="font-semibold mt-4">Why the Delay?</h4>
        <p>This delay allows our system enough time to:</p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li><strong>Review all survey completions</strong></li>
          <li><strong>Identify and correct any reversals or reconciliations</strong></li>
        </ul>
        <p className="mt-4">Surveys deemed <strong>fraudulent or unacceptable</strong> by our research partners may <strong>not</strong> count towards your monthly totals.</p>
        <h4 className="font-semibold mt-4">Understanding Adjustments to Monthly Totals</h4>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li>
            <strong>Example</strong>:
            <ul className="list-disc list-inside ml-6">
              <li>If you complete <strong>30 surveys</strong> in a month and <strong>2</strong> are reversed due to quality issues, your total for that month will be adjusted to <strong>28 surveys</strong>.</li>
            </ul>
          </li>
        </ul>
        <h4 className="font-semibold mt-4">Key Points to Remember</h4>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li><strong>Accuracy Matters</strong>: Always provide honest and thoughtful responses to ensure your surveys count.</li>
          <li><strong>Stay Informed</strong>: Check your account regularly to see any adjustments made to your survey totals.</li>
          <li><strong>We're Here to Help</strong>: If you have questions or concerns about your survey completions or bonuses, please contact our support team.</li>
        </ul>
        <p className="mt-4">We appreciate your understanding and cooperation in helping us maintain high-quality standards. This process ensures a fair and rewarding experience for all our users.</p>
      </>
    ),
  },
];

const SurveyRulesPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-10 px-2 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-3xl font-extrabold text-center text-gray-800 mb-8">Participation Rules</h2>
        <p className="text-gray-700 text-left mb-8">
          We want to help you succeed! To ensure a great experience for everyone, you must follow the rules listed below and agree to our{' '}
          <a href="/terms" className="text-blue-500 underline">Terms of Service</a>. If you disagree with any part of these terms, you should not use our website and may face an immediate ban, making you ineligible to receive payment for any incentives or monthly bonuses. Thank you for your understanding!
        </p>
        <div className="space-y-6 text-left">
          {surveyRules.map((rule, index) => (
            <div
              key={index}
              className="rounded-lg bg-gray-100 p-6 hover:shadow-md transition-shadow duration-300"
            >
              <h3 className="text-xl font-semibold mb-4">{rule.title}</h3>
              <div className="text-gray-700 text-base">{rule.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SurveyRulesPage;
