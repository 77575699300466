import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMessage } from '../services/MessageService';
import { AuthContext } from '../auth/authcontext';

function EmailConfirmation() {
  const { key } = useParams();
  const { addMessage } = useMessage();
  const navigate = useNavigate();
  const { setUserDetails } = useContext(AuthContext);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [loading, setLoading] = useState(true);
  const hasAttempted = useRef(false);

  useEffect(() => {
    async function confirmEmail() {
      try {
        if (!hasAttempted.current) {
          hasAttempted.current = true;
          
          // Confirm email and get tokens
          const response = await axios.get(`/api/auth/confirm-email/${key}/`);
          
          if (response.status === 200 && response.data.token) {
            // Format the response data to match what setUserDetails expects
            const jwtToken = {
              access: response.data.token,
              refresh: response.data.refresh,
              user: response.data.user
            };
            
            // Set the authentication details
            setUserDetails(jwtToken);
            
            addMessage("Your email has been confirmed and you're now logged in!", "success");
            setHasConfirmed(true);
            setLoading(false);
            
            // Redirect to onboarding
            navigate("/dashboard/onboarding");
          }
        }
      } catch (error) {
        setLoading(false);
        const errorMessage = error.response?.status === 404
          ? "Invalid or expired confirmation link. Please try again."
          : "An error occurred while confirming your email. Please try again.";
        addMessage(errorMessage, "error");
        setHasConfirmed(true);
        navigate("/login");
      }
    }

    confirmEmail();
  }, [key, hasConfirmed, addMessage, navigate, setUserDetails]);

  return (
    <div className="container mx-auto my-10 p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Email Confirmation</h2>
      
      {loading ? (
        <div className="text-center">
          <p className="mb-4">Processing your email confirmation...</p>
          <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto"></div>
        </div>
      ) : (
        <p>{hasConfirmed ? "Your email has been confirmed!" : "There was an issue with the confirmation."}</p>
      )}
    </div>
  );
}

export default EmailConfirmation;