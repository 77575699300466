import React, { useState, useContext } from 'react';
import { AuthContext } from '../../auth/authcontext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  GenderChoices,
  HHIChoices,
  EducationChoices,
  MaritalStatusChoices,
  EthnicityChoices,
  RaceChoices,
  HispanicOriginChoices,
  EmploymentStatusChoices,
  PersonalIndustryChoices,
  AgeGenderChildrenChoices,
  ParentalStatusChoices,
} from '../../dropdowns/dropdownChoices';
import { MultiSelect } from "@/components/ui/combobox"

const OnboardingFlow = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [profileData, setProfileData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  
  const questions = [
    {
      key: 'date_of_birth',
      label: 'When were you born?',
      type: 'date',
    },
    {
      key: 'gender',
      label: 'What is your gender?',
      type: 'select',
      options: GenderChoices
    },
    {
      key: 'ethnicity',
      label: 'What is your ethnicity?',
      type: 'select',
      options: EthnicityChoices
    },
    {
      key: 'race',
      label: 'What is your race?',
      type: 'select',
      options: RaceChoices
    },
    {
      key: 'hispanic_origin',
      label: 'Are you of Hispanic origin?',
      type: 'select',
      options: HispanicOriginChoices
    },
    {
      key: 'hhi',
      label: 'What is your household income range?',
      type: 'select',
      options: HHIChoices
    },
    {
      key: 'employment',
      label: 'What is your employment status?',
      type: 'select',
      options: EmploymentStatusChoices
    },
    {
      key: 'personal_industry',
      label: 'What industry do you work in?',
      type: 'select',
      options: PersonalIndustryChoices
    },
    {
      key: 'marital_status',
      label: 'What is your marital status?',
      type: 'select',
      options: MaritalStatusChoices
    },
    {
      key: 'parent_status',
      label: 'What is your parental status?',
      type: 'select',
      options: ParentalStatusChoices
    },
    {
        key: 'age_gender_children',
        label: 'Do you have children? If so, what are their ages and genders?',
        type: 'multiselect',
        options: AgeGenderChildrenChoices
      },
    {
      key: 'education',
      label: 'What is your highest level of education?',
      type: 'select',
      options: EducationChoices
    },
    {
      key: 'zipcode',
      label: 'What is your zipcode?',
      type: 'text',
      inputMode: 'numeric',
      pattern: '[0-9]*'
    }
  ];

  const handleInputChange = async (value) => {
    if (isSaving) return;  // Prevent multiple submissions
    
    const currentQuestion = questions[currentStep];
    setIsSaving(true);
    
    try {
      // Update local state
      setProfileData(prev => ({
        ...prev,
        [currentQuestion.key]: value
      }));
  
      // Save to backend immediately
      await axios.patch(
        '/api/users/profile/',
        { [currentQuestion.key]: value },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // Advance to next question
      if (currentStep < questions.length - 1) {
        setTimeout(() => setCurrentStep(currentStep + 1), 500);
      } else {
        navigate('/dashboard/survey-entry');
      }
    } catch (error) {
      // Could add error message here
    } finally {
      setIsSaving(false);
    }
  };
  
 
  const handleSubmit = () => {
    navigate('/dashboard');
  };

  const handleSkip = async () => {
    if (isSaving) return;
    
    try {
      setIsSaving(true);
      // Save null value for skipped question
      await handleInputChange(null);
    } catch (error) {
    }
  };


    const renderInput = (question) => {
        switch (question.type) {
            case 'date':
            return (
                <div>
                <input
                    type="date"
                    value={profileData[question.key] || ''}
                    onChange={(e) => {
                    setProfileData(prev => ({
                        ...prev,
                        [question.key]: e.target.value
                    }));
                    }}
                    className="w-full p-4 text-lg bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 mb-6"
                />
                <button
                    onClick={() => handleInputChange(profileData[question.key])}
                    disabled={isSaving}
                    className={`w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors ${
                    isSaving ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                >
                    {isSaving ? 'Saving...' : 'Continue'}
                </button>
                </div>
            );
            case 'select':
            return (
                <select
                onChange={(e) => handleInputChange(e.target.value)}
                className="w-full p-4 text-lg bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                >
                <option value="">Select an option</option>
                {Object.entries(question.options).map(([value, label]) => (
                    <option key={value} value={value}>
                    {label}
                    </option>
                ))}
                </select>
            );
            case 'multiselect':
                return (
                  <div className="space-y-4">
                    <MultiSelect
                      options={question.options}
                      value={profileData[question.key] || []}
                      onChange={(newValues) => {
                        setProfileData(prev => ({
                          ...prev,
                          [question.key]: newValues
                        }));
                      }}
                      placeholder="Select children's ages and genders..."
                    />
                    <button
                      onClick={() => handleInputChange(profileData[question.key] || [])}
                      disabled={isSaving}
                      className={`w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors ${
                        isSaving ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      {isSaving ? 'Saving...' : 'Continue'}
                    </button>
                  </div>
                );
            default:
                return (
                  <div>
                    <input
                      type={question.type}
                      inputMode={question.inputMode}
                      pattern={question.pattern}
                      value={profileData[question.key] || ''}
                      onChange={(e) => {
                        setProfileData(prev => ({
                          ...prev,
                          [question.key]: e.target.value
                        }));
                      }}
                      className="w-full p-4 text-lg bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 mb-6"
                    />
                    <button
                      onClick={() => handleInputChange(profileData[question.key])}
                      disabled={isSaving}
                      className={`w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors ${
                        isSaving ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      {isSaving ? 'Saving...' : 'Continue'}
                    </button>
                  </div>
                );
    }
  };

  const currentQuestion = questions[currentStep];

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="w-full max-w-md mx-auto pt-8 md:pt-12">
        {/* Welcome text - only shown on first step */}
        <AnimatePresence>
          {currentStep === 0 && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mb-8 text-center"
            >
              <h1 className="text-3xl font-bold text-gray-900 mb-4">Welcome!</h1>
              <p className="text-gray-600 mb-2">
                Help us personalize your experience by answering a few questions about yourself. The more we know, the better we can match you with surveys and the more you can earn!
              </p>
              <p className="text-gray-500 text-sm">
                The answers to these questions help us match you with the most relevant survey opportunities.
              </p>
            </motion.div>
          )}
        </AnimatePresence>
  
        {/* Progress bar */}
        <div className="mb-8">
          <div className="h-2 bg-gray-200 rounded-full">
            <div
              className="h-2 bg-blue-500 rounded-full transition-all duration-500"
              style={{ width: `${((currentStep + 1) / questions.length) * 100}%` }}
            />
          </div>
          <p className="text-sm text-gray-500 mt-2">
            Question {currentStep + 1} of {questions.length}
          </p>
        </div>
  
        <AnimatePresence mode="wait">
          <motion.div
            key={currentStep}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-xl shadow-lg p-6 md:p-8"
          >
            <h2 className="text-2xl font-semibold mb-6">
              {currentQuestion.label}
            </h2>
            {renderInput(currentQuestion)}
          </motion.div>
        </AnimatePresence>
  
        {/* Skip button */}
        <button
          onClick={handleSkip}
          disabled={isSaving}
          className={`mt-4 text-gray-500 hover:text-gray-700 text-sm ${
            isSaving ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSaving ? 'Saving...' : 'Skip this question'}
        </button>
      </div>
    </div>
  );
};

export default OnboardingFlow;