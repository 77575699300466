// frontend/src/components/DashboardLayout.jsx
import ProtectedRoute from "./auth/protectedroute";
import AppNav from "./appnav";
import React, { useContext } from "react";
import { AuthContext } from '../auth/authcontext';
import { useLocation } from "react-router-dom";
import { FaHome, FaChartLine, FaWallet, FaCompass, FaPenSquare, FaUser, FaLock } from "react-icons/fa";

const DashboardLayout = ({ children }) => {
  const { isAuthenticated, isSuperuser, user } = useContext(AuthContext);
  const location = useLocation();


  const sidebarNavItems = [
    {
      title: "Survey Opportunities",
      href: "/dashboard/survey-entry",
      icon: <FaHome />,
      permission: isAuthenticated,
      active: location.pathname === "/dashboard/survey-entry",
    },
    {
      title: "Leaderboard",
      href: "/dashboard/leaderboard",
      icon: <FaChartLine />,
      permission: isAuthenticated,
      active: location.pathname === "/dashboard/leaderboard",
    },
    {
      title: "Redeem",
      href: "/dashboard/redeem-rewards",
      icon: <FaWallet />,
      permission: isAuthenticated,
      active: location.pathname.includes("/dashboard/redeem-rewards"),
    },
    {
      title: "Survey Strategy",
      href: "/dashboard/survey-strategy",
      icon: <FaCompass />,
      permission: isAuthenticated,
      active: location.pathname.includes("/dashboard/survey-strategy"),
    },
    {
      title: "Participation Rules",
      href: "/dashboard/survey-rules",
      icon: <FaPenSquare />,
      permission: isAuthenticated,
      active: location.pathname.includes("/dashboard/survey-rules"),
    },
    {
      title: "User Profile",
      href: "/dashboard/profile",
      icon: <FaUser />,
      permission: isAuthenticated,
      active: location.pathname.includes("/dashboard/profile"),
    },
    // Admin section - only visible to superusers
    {
      title: "Admin Panel",
      href: "/dashboard/admin",
      icon: <FaLock />,
      permission: isAuthenticated && isSuperuser,
      active: location.pathname.includes("/dashboard/admin"),
    },
  ];

  const filteredNavItems = sidebarNavItems.filter(item => item.permission);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Main content area */}
      <div className="flex flex-col md:flex-row flex-grow container">
        <div className="w-full flex-none md:w-64">
          <AppNav items={filteredNavItems} />
        </div>
        <div className="w-full md:p-12">
          <ProtectedRoute>{children}</ProtectedRoute>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;