import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../auth/authcontext';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // Not loading and not authenticated, so redirect to login
      navigate('/login');
    }
  }, [isLoading, isAuthenticated, navigate]);

  if (isAuthenticated) {
    return <>{children}</>;
  } else if (isLoading) {
    return <div>Loading...</div>;
  } else {
    // return null or a fragment; navigation is handled by useEffect
    return null;
  }
};

export default ProtectedRoute;