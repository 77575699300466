import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import Home from './pages/Home';
import ErrorPage from './ErrorPage';
import LoginPage from './pages/Login';
import Profile from './pages/dashboard/Profile';
import Dashboard from './pages/dashboard/Dashboard';
import LogoutPage from './pages/Logout';
import SignupPage from './pages/SignupPage';
import PrivacyPage from './pages/PrivacyPolicy';
import TermsPage from './pages/Terms';
import AboutPage from './pages/About';
import FaqPage from './pages/Faq';
import EmailConfirmation from './pages/EmailConfirmation';
import Unsubscribe from './pages/Unsubscribe';
import SurveyRulesPage from './pages/dashboard/SurveyRules';
import SurveyStrategyPage from './pages/dashboard/SurveyStrategy';
import ChangePasswordPage from './pages/dashboard/ChangePassword';
import SurveyOpps from './pages/dashboard/SurveyOpps';
import CintLinkEntry from './pages/dashboard/CintLinkEntry';
import Redeem from './pages/dashboard/Redeem';
import Leaderboard from './pages/dashboard/Leaderboard';
import AuthProvider from './auth/authprovider';
import { MessageProvider } from './services/MessageService';
import PayPalCallback from './pages/PayPalCallback';
import CintCallback from './pages/CintCallback';
import AccountDeletion from './pages/dashboard/AccountDeletion';
import AdminPanel from './components/admin/AdminPanel';
import ModelManager from './components/admin/components/ModelManager';
import TaskManager from './components/admin/components/TaskManager';
import SignupAnalytics from './components/admin/components/AdminAnalytics';
import ProtectedSuperUserRoute from './components/auth/ProtectedSuperUserRoute';
import OnboardingFlow from './pages/onboarding/OnboardingFlow';
import './index.css';

const DisabledRoute = () => {
  // You can redirect to any appropriate page
  return <Navigate to="/dashboard/survey-entry" replace />;
};

// Router configuration with v7 future flags
const routes = [
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/login', element: <LoginPage /> },
      { path: '/logout', element: <LogoutPage /> },
      { path: '/signup', element: <SignupPage /> },
      { path: '/privacy', element: <PrivacyPage /> },
      { path: '/terms', element: <TermsPage /> },
      { path: '/about', element: <AboutPage /> },
      { path: '/faq', element: <FaqPage /> },
      { path: '/email-confirmation/:key', element: <EmailConfirmation /> },
      { path: '/unsubscribe', element: <Unsubscribe /> },
      { path: '/paypal-callback', element: <PayPalCallback /> },
      { path: '/cint-callback', element: <CintCallback /> },
      {
        path: '/dashboard',
        element: <Dashboard />,
        children: [
          { path: 'onboarding', element: <OnboardingFlow /> },
          { path: 'profile', element: <Profile /> },
          { path: 'change-password', element: <ChangePasswordPage /> },
          { path: 'survey-opps', element: <DisabledRoute /> },
          { path: 'survey-entry', element: <CintLinkEntry /> },
          { path: 'redeem-rewards', element: <Redeem /> },
          { path: 'leaderboard', element: <Leaderboard /> },
          { path: 'survey-strategy', element: <SurveyStrategyPage /> },
          { path: 'survey-rules', element: <SurveyRulesPage /> },
          { path: 'delete-account', element: <AccountDeletion /> },
        ],
      },
      {
        path: '/dashboard/admin',
        element: (
          <ProtectedSuperUserRoute>
            <AdminPanel />
          </ProtectedSuperUserRoute>
        ),
        children: [
          { path: '', element: <ModelManager /> },
          { path: 'tasks', element: <TaskManager /> },
          { path: 'analytics', element: <SignupAnalytics /> },
        ]
      }
    ],
  },
];


// Create router with basic configuration
const router = createBrowserRouter(routes);

// Root component with all providers
const Root = () => {
  return (
    <React.StrictMode>
      <ReduxProvider store={store}>
        <AuthProvider>
          <MessageProvider>
            <RouterProvider router={router} />
          </MessageProvider>
        </AuthProvider>
      </ReduxProvider>
    </React.StrictMode>
  );
};

// Render the app
ReactDOM.createRoot(document.getElementById('root')).render(<Root />);

export default router;