// frontend/src/components/admin/components/TopBar.jsx
import React from 'react';

export const TopBar = () => {
  return (
    <div className="bg-white shadow-sm h-16 flex items-center px-8">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-xl font-semibold text-gray-800">LevelRev Admin</h1>
        <div className="flex items-center space-x-4">
          {/* Add more items here later */}
          <span className="text-sm text-gray-600">Admin User</span>
        </div>
      </div>
    </div>
  );
};

export default TopBar;