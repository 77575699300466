// frontend/src/auth/authprovider.js
import React, { useState, useEffect, useMemo } from 'react';
import { AuthContext } from "./authcontext";
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Use with curly brackets for jwt-decode

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [csrfToken, setCsrfToken] = useState(null);

  // Utility function to check if token is expired
  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return decodedToken.exp < currentTime;
    } catch (error) {

      return true;
    }
  };

  // Load CSRF token on initial load
  useEffect(() => {
    const csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken='));
    if (csrfToken) {
      setCsrfToken(csrfToken.split('=')[1]);
      axios.defaults.headers.common['X-CSRFToken'] = csrfToken.split('=')[1];
    }
  }, []);

  // Check token validity and refresh if necessary
  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    if (storedToken && !isTokenExpired(storedToken)) {
      const validateTokenAndSetUser = async () => {
        try {
          const response = await axios.get('/api/auth/user/', {
            headers: { Authorization: `Bearer ${storedToken}` },
          });
          setToken(storedToken);
          setUser(response.data);
          setIsAuthenticated(true);
        } catch (error) {
          localStorage.removeItem('accessToken');
          const refreshToken = localStorage.getItem('refreshToken');
          if (refreshToken && !isTokenExpired(refreshToken)) {
            try {
              const refreshResponse = await axios.post('/api/auth/token/refresh/', { refresh: refreshToken });
              const newToken = refreshResponse.data.access;
              localStorage.setItem('accessToken', newToken);
              setToken(newToken);
              const userResponse = await axios.get('/api/auth/user/', {
                headers: { Authorization: `Bearer ${newToken}` },
              });
              setUser(userResponse.data);
              setIsAuthenticated(true);
            } catch (error) {
              localStorage.removeItem('refreshToken');
              setIsAuthenticated(false);
            }
          }
        } finally {
          setIsLoading(false);
        }
      };
      validateTokenAndSetUser();
    } else {
      setIsLoading(false);
    }
  }, []);

  // frontend/src/auth/authprovider.js
  const contextValue = useMemo(() => ({
    token,
    user,
    isAuthenticated,
    isLoading,
    csrfToken,
    isSuperuser: user?.is_superuser || false, 
    hasUsablePassword: user?.has_usable_password || false,
    isAnonymous: user?.is_anonymous || false,
    setUserDetails: (jwtToken) => {
      localStorage.setItem('refreshToken', jwtToken.refresh);
      setToken(jwtToken.access);
      localStorage.setItem('accessToken', jwtToken.access);
      setUser(jwtToken.user);
      setIsAuthenticated(true);
      setIsLoading(false);
    },
    logout: () => {
      setToken(null);
      setUser(null);
      setIsAuthenticated(false);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
  }), [token, user, isAuthenticated, isLoading, csrfToken]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;