import { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

const TaskManager = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [taskType, setTaskType] = useState('all');
  const [filters, setFilters] = useState({
    status: '',
    task_name: '',
    date_range: '',
  });

  const statusColors = {
    'SUCCESS': 'bg-green-100 text-green-800',
    'FAILURE': 'bg-red-100 text-red-800',
    'PENDING': 'bg-yellow-100 text-yellow-800',
    'STARTED': 'bg-blue-100 text-blue-800',
    'RETRY': 'bg-purple-100 text-purple-800',
    'REVOKED': 'bg-gray-100 text-gray-800',
    'SCHEDULED': 'bg-blue-100 text-blue-800',
  };

  const fetchTasks = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const params = new URLSearchParams({
        page,
        type: taskType,
        ...filters,
      });
      
      const response = await axios.get(`/api/custom-admin/tasks/?${params}`, {
        withCredentials: true
      });
      
      setTasks(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
    } catch (error) {
      setError(error.response?.data?.detail || 'Failed to fetch tasks');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [page, filters, taskType]);

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="p-4 border-b space-y-4">
        <h2 className="text-lg font-semibold">Task Manager</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <select
            className="rounded-md border border-gray-300 p-2"
            value={taskType}
            onChange={(e) => setTaskType(e.target.value)}
          >
            <option value="all">All Tasks</option>
            <option value="scheduled">Scheduled Tasks</option>
            <option value="executions">Task Executions</option>
          </select>
          
          {taskType !== 'scheduled' && (
            <>
              <select
                className="rounded-md border border-gray-300 p-2"
                value={filters.status}
                onChange={(e) => setFilters({ ...filters, status: e.target.value })}
              >
                <option value="">All Statuses</option>
                {Object.keys(statusColors).map(status => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </select>
              
              <input
                type="text"
                placeholder="Search by task name"
                className="rounded-md border border-gray-300 p-2"
                value={filters.task_name}
                onChange={(e) => setFilters({ ...filters, task_name: e.target.value })}
              />
              
              <select
                className="rounded-md border border-gray-300 p-2"
                value={filters.date_range}
                onChange={(e) => setFilters({ ...filters, date_range: e.target.value })}
              >
                <option value="">All Time</option>
                <option value="24h">Last 24 Hours</option>
                <option value="7d">Last 7 Days</option>
                <option value="30d">Last 30 Days</option>
              </select>
            </>
          )}
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Task Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {taskType === 'scheduled' ? 'Last Run' : 'Started'}
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {taskType === 'scheduled' ? 'Schedule' : 'Duration'}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {loading ? (
              <tr>
                <td colSpan="4" className="text-center py-4">Loading...</td>
              </tr>
            ) : tasks.map((task) => (
              <tr key={task.task_id || task.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {task.task_name || task.name}
                  </div>
                  <div className="text-sm text-gray-500">
                    {task.task_id || task.task}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    statusColors[task.status || 'SCHEDULED']
                  }`}>
                    {task.status || (task.enabled ? 'SCHEDULED' : 'DISABLED')}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {task.date_created ? 
                    format(new Date(task.date_created), 'PPp') :
                    (task.last_run_at ? 
                      format(new Date(task.last_run_at), 'PPp') : 
                      'Never')}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {task.schedule || 
                   (task.duration ? `${task.duration.toFixed(2)}s` : '-')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="px-4 py-3 border-t border-gray-200">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Page {page} of {totalPages}
            </p>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => setPage(Math.max(1, page - 1))}
              disabled={page === 1}
              className="px-3 py-1 border rounded disabled:opacity-50"
            >
              Previous
            </button>
            <button
              onClick={() => setPage(Math.min(totalPages, page + 1))}
              disabled={page === totalPages}
              className="px-3 py-1 border rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskManager;