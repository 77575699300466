import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link, Form, useSubmit } from 'react-router-dom';
import { useMessage } from '../services/MessageService';
import { useSelector } from 'react-redux';
import { CountryChoices } from '../dropdowns/dropdownChoices';

const SignupForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password1: '',
    password2: '',
    country: '',
    terms_accepted: false
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const submit = useSubmit();
  const { addMessage } = useMessage();
  const utmData = useSelector((state) => state.utm);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    
    if (!formData.terms_accepted) {
      addMessage('Please accept the Terms of Service and Privacy Policy to continue.', 'error');
      return;
    }
    
    setIsSubmitting(true);

    try {
      const registrationData = {
        ...formData,
        utm_data: utmData
      };

      const makeRequest = async (attempt = 1) => {
        try {
          return await axios.post('/api/auth/registration/', registrationData, {
            timeout: 15000,
          });
        } catch (error) {
          if (attempt < 2 && !error.response) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            return makeRequest(attempt + 1);
          }
          throw error;
        }
      };

      await makeRequest();
      addMessage('Registration successful! Please check your email to verify your account.', 'success');
      navigate('/login');

    } catch (err) {

      if (err.message?.includes('timeout')) {
        addMessage('The request timed out. Please check your connection and try again. If you are using a VPN, you may want to temporarily disable it.', 'error');
      } else if (err.response?.data) {
        const errorData = err.response.data;
        Object.entries(errorData).forEach(([key, messages]) => {
          if (Array.isArray(messages)) {
            messages.forEach(message => addMessage(`${key}: ${message}`, 'error'));
          } else if (typeof messages === 'string') {
            addMessage(`${key}: ${messages}`, 'error');
          }
        });
      } else {
        addMessage('An unexpected error occurred. Please try again later.', 'error');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-6">
      <Form 
        onSubmit={handleSubmit} 
        className="space-y-4"
        method="POST"
        action="/api/auth/registration/"
      >
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
          placeholder="Username"
          required
          disabled={isSubmitting}
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          required
          disabled={isSubmitting}
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="password"
          name="password1"
          value={formData.password1}
          onChange={handleChange}
          placeholder="Password"
          required
          disabled={isSubmitting}
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="password"
          name="password2"
          value={formData.password2}
          onChange={handleChange}
          placeholder="Confirm Password"
          required
          disabled={isSubmitting}
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        
        <select
          name="country"
          value={formData.country}
          onChange={handleChange}
          required
          disabled={isSubmitting}
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select Country</option>
          {Object.entries(CountryChoices).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>

        <div className="flex items-start space-x-2 text-sm">
          <input
            type="checkbox"
            name="terms_accepted"
            id="terms_accepted"
            checked={formData.terms_accepted}
            onChange={handleChange}
            className="mt-1"
            required
          />
          <label htmlFor="terms_accepted" className="text-gray-600">
            I agree to the{' '}
            <Link to="/terms" className="text-blue-500 hover:text-blue-600">
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link to="/privacy" className="text-blue-500 hover:text-blue-600">
              Privacy Policy
            </Link>
          </label>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="btn-primary w-full text-white py-2 rounded-lg transition duration-200 disabled:opacity-50"
        >
          {isSubmitting ? 'Signing Up...' : 'Sign Up'}
        </button>
      </Form>
    </div>
  );
};

export default SignupForm;