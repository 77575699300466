import React, { useState } from 'react';
import axios from 'axios';
import { useMessage } from '../services/MessageService';

function Unsubscribe() {
  const { addMessage } = useMessage();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [mailchimpStatus, setMailchimpStatus] = useState(null);

  const handleUnsubscribe = async (e) => {
    e.preventDefault();

    if (!email) {
      addMessage("Please provide your email address to unsubscribe.", "error");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post('/api/auth/unsubscribe/', { email });

      if (response.status === 200) {
        setUnsubscribed(true);
        setMailchimpStatus(response.data.mailchimp_updated);
        
        // Show appropriate success message based on what was updated
        if (response.data.mailchimp_updated) {
          addMessage("You have been successfully unsubscribed from all communications.", "success");
        } else {
          addMessage(
            "You have been unsubscribed from our database, but there was an issue updating the mailing list. " +
            "Please contact support if you continue to receive emails.", 
            "warning"
          );
        }
      }
    } catch (error) {
      const errorMessage = error.response?.data?.detail || 
        "An error occurred while processing your unsubscribe request. Please try again.";
      addMessage(errorMessage, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto my-10 p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Unsubscribe from Emails</h2>
      <p>Unsubscribing removes your from our email list, but your LevelRev account will still exist.</p>
      {unsubscribed ? (
        <div className="space-y-4">
          <p className="text-green-600">
            You have successfully unsubscribed from our database.
          </p>
          {mailchimpStatus === false && (
            <p className="text-yellow-600">
              However, there was an issue updating the mailing list. If you continue 
              to receive emails, please contact our support team.
            </p>
          )}
          <p className="text-gray-600 mt-4">
            We're sorry to see you go! If you change your mind, you can always 
            resubscribe from your account settings.
          </p>
        </div>
      ) : (
        <form onSubmit={handleUnsubscribe} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter your email address"
            />
          </div>
          <div>
            <button
              type="submit"
              disabled={loading}
              className={`w-full py-2 px-4 rounded-md text-white ${
                loading ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'
              }`}
            >
              {loading ? 'Processing...' : 'Unsubscribe'}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default Unsubscribe;