import React from 'react';

const surveyStrategySteps = [
  {
    title: "Be honest",
    content: (
      <p>
        You will succeed the most by being honest. There's no need to cheat; there are plenty of surveys for everyone. If you try to take shortcuts, you risk being banned. Be patient, and you will see your efforts pay off.
      </p>
    ),
  },
  {
    title: "Do not use a VPN",
    content: (
      <p>
        Don't use a VPN while taking surveys. Researchers need quality data, and VPNs can interfere with this.
      </p>
    ),
  },
  {
    title: "Complete your profile",
    content: (
      <p>
        Before each survey you will be asked some profile questions. These questions help our partners find surveys that you should qualify to complete. It’s important that you are honest when answering these questions. Be patient.
      </p>
    ),
  },
  {
    title: "Get matched to surveys",
    content: (
      <p>
        Profile questions help match you to the right surveys. Once we get to know more about you, it will be easier to find surveys for you to complete. Honest answers help you get more surveys.
      </p>
    ),
  },
];

const SurveyStrategyPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-10 px-2 sm:px-6 lg:px-8 text-left">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-3xl font-extrabold text-center text-gray-800 mb-8">Survey Strategy</h2>
        
        <p className="text-gray-700 mb-6">
          Unlock incredible earning potential by completing surveys with us! We offer highly competitive incentives, with the opportunity to earn up to 60% of partner rewards once you reach level 7. Set your sights on leveling up as quickly as you can to maximize your share and start earning more!
        </p>
        
        <p className="text-gray-700 mb-6">
          In addition to our generous 60% incentive, we're rewarding our top 3 survey takers each month with exclusive bonuses of $500, $300, and $200. To qualify for these bonuses, simply complete an average of one survey per day throughout the month. Start now and take your rewards to the next level!
        </p>
        
        <p className="text-gray-700 mb-6">
          We want to see you succeed. We are doing our best to pay fair incentives and help you earn meaningful rewards each month. There are no shortcuts to success. You must put in an honest effort. Follow these steps to earn points and complete surveys successfully:
        </p>
        
        <p className="text-gray-700 mb-8">
          Our strategy is straightforward: provide honest responses, and completing surveys will be a breeze. When you first sign up, it might take a few attempts for our system to accurately match you with the best survey opportunities. Stick with it, and you'll soon be matched with surveys that fit you perfectly.
        </p>
        
        <div className="border-t border-gray-300 my-6" />
        
        <blockquote className="text-gray-500 italic mb-6 border-l-4 pl-4 border-indigo-600">
          "Great things come from hard work and perseverance. No excuses." — Kobe Bryant
        </blockquote>
        
        <blockquote className="text-gray-500 italic mb-6 border-l-4 pl-4 border-indigo-600">
          "Opportunities are usually disguised as hard work, so most people don’t recognize them." — Ann Landers
        </blockquote>
        
        <blockquote className="text-gray-500 italic mb-8 border-l-4 pl-4 border-indigo-600">
          "Success is the sum of small efforts, repeated day in and day out." — Robert Collier
        </blockquote>
        
        <div className="space-y-6">
          {surveyStrategySteps.map((step, index) => (
            <div
              key={index}
              className="rounded-lg bg-gray-100 p-6 hover:shadow-md transition-shadow duration-300"
            >
              <h3 className="text-xl font-semibold mb-4">{step.title}</h3>
              <div className="text-gray-700 text-base">{step.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SurveyStrategyPage;